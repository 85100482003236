import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import moment from 'moment';
import { connect } from '../../Store';
import { __, formatPrice } from '../../Utils';
import { Link } from 'react-router-dom';
import { Button } from 'oxyrion-ui/lib';
import LineChart from '../../Components/LineChart';
import API2 from '../../API2';

const Title = styled.div`
  width: 100%;
  margin-top: ${rem(24)};
  color: #ba6dba;
  font-size: ${rem(24)};
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${rem(32)};
`;

const DailyInfoWrapper = styled.div`
  margin-top: ${rem(24)};
  font-size: ${rem(12)};
  display: flex;
  flex-direction: column;
  gap: ${rem(18)};
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.media.m`
    flex-direction: row;
  `};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
    font-size: ${rem(16)};
    margin-top: ${rem(16)};
  }

  .col {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: ${rem(8)};
    padding: ${rem(8)};
    width: 100%;

    ${({ theme }) => theme.media.m`
      width: 40%;
    `};

    .daily-header {
      margin-left: ${rem(12)};
      height: ${rem(40)};
      color: #a448a3;
      display: flex;
      align-items: center;
      margin-bottom: ${rem(14)};
      font-size: ${rem(20)};
    }
  }

  .barchart-wrapper {
    width: 100%;
    margin-right: ${rem(30)};

    ${({ theme }) => theme.media.m`
     width: 60%;
    `};
  }

  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
    color: #434245;
  }
`;

const Line = styled.div`
  height: ${rem(1)};
  width: 100%;
  margin-top: ${rem(14)};
  background-color: #f8f1f8;
`;

class DailyCashDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cashSummary: [],
      dailyStats: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const todaysDate = moment();
      const oneDaysAgoDate = moment(todaysDate).subtract(1, 'd');
      const twoDaysAgoDate = moment(todaysDate).subtract(2, 'd');

      const [
        cashSummaryLastDaysOne,
        cashSummaryLastDaysTwo,
        cashSummaryLastDaysThree,
        cashSummary,
        cashBalance,
      ] = await Promise.all([
        API2.getCashRegisterSummary(
          this.props.firm,
          this.formatParams(todaysDate, this.props.cashRegisterId),
        ),
        API2.getCashRegisterSummary(
          this.props.firm,
          this.formatParams(oneDaysAgoDate, this.props.cashRegisterId),
        ),
        API2.getCashRegisterSummary(
          this.props.firm,
          this.formatParams(twoDaysAgoDate, this.props.cashRegisterId),
        ),
        API2.getCashRegisterSummary(
          this.props.firm,
          this.formatParams(todaysDate, this.props.cashRegisterId),
        ),
        API2.getActualCashRegisterCashBalanceAction(
          this.props.firm,
          this.formatParams(todaysDate, this.props.cashRegisterId),
        ),
      ]);

      const lastDaysSummaryData = [
        {
          name: twoDaysAgoDate.format('Do MMM'),
          amount: cashSummaryLastDaysThree.total.amount,
        },
        {
          name: oneDaysAgoDate.format('Do MMM'),
          amount: cashSummaryLastDaysTwo.total.amount,
        },
        {
          name: todaysDate.format('Do MMM'),
          amount: cashSummaryLastDaysOne.total.amount,
        },
      ];

      const dailyStatsData = [
        {
          label: __('Stav v pokladni'),
          value: formatPrice(cashBalance.balance),
        },
        {
          label: __('Počet dokladov'),
          value: cashSummary.number_of_recipes,
        },
        {
          label: __('Obrat'),
          value: formatPrice(cashSummary.total),
        },
      ];

      this.setState({
        cashSummary: lastDaysSummaryData,
        dailyStats: dailyStatsData,
      });
    } catch (error) {
      console.error(error);
    }
  }

  formatParams(date, cashId) {
    return {
      fromDate: date.startOf('day').toISOString(),
      toDate: date.endOf('day').toISOString(),
      cashRegisterId: cashId,
    };
  }

  dailyChartTitleString() {
    const currentDate = moment();

    const todayDay = currentDate.startOf('day').format('Do MMM');
    const threeDaysAgo = currentDate
      .subtract(2, 'd')
      .startOf('day')
      .format('Do MMM');

    return `${threeDaysAgo} - ${todayDay}`;
  }

  render() {
    const { cashSummary, dailyStats } = this.state;

    return (
      <React.Fragment>
        <Title>
          {__('Pokladňa')} - {this.props.name}
        </Title>
        <DailyInfoWrapper>
          <div className="col">
            <div className="daily-header">{__('Dnešný súhrn')}</div>
            <div>
              {dailyStats.map(stat => (
                <React.Fragment>
                  <div className="row">
                    <div className="label">{stat.label}:</div>
                    <div className="value">{stat.value}</div>
                  </div>
                  <Line />
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="barchart-wrapper">
            <LineChart
              data={cashSummary}
              title={__('Predaje za posledné 3 dni')}
              subTitle={this.dailyChartTitleString()}
              appLang={this.props.appLang}
            />
          </div>
        </DailyInfoWrapper>

        <ButtonWrapper>
          <Link
            style={{
              textDecoration: 'none',
            }}
            to="./daily-overview"
          >
            <Button primary>{__('Zobraziť viac')}</Button>
          </Link>
        </ButtonWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(DailyCashDashboard);

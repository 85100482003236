import React from 'react';
import BaseTable from '../BaseTable';
import { connect } from '../../Store';
import { __, FORMAT_HUMANE_DATETIME, formatDate } from '../../Utils';
import tableHeaders from '../../ContentConfig/contactsRegisterList';
import API2 from '../../API2';
import { StyledA } from '../../Components/ReusableComponents';
import moment from 'moment';
import { searchCashRegisters } from '../../Utils/ApiSearchFunctions';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import PopUpWrapper from '../../Components/PopUpWrapper';
import RegisterCustomer from '../RegisterCustomer';

class ContactsRegisterList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Registrácie cez kasu');
    this.accessors = [
      'name',
      'email',
      'phone',
      'created_date',
      'register_state',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearch: '',
      showRegisterForm: false,
      showFilter: false,
      showAttachmentsPopUp: false,
      popupData: [],
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'cashRegisterId',
          label: __('Pokladňa'),
          value: '',
          type: 'search-select',
          onSearch: q => searchCashRegisters(q, this.props.firm),
        },
      ],
    };
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_CRM_V2_BASE_URL}/contacts/${item._id}`}
          >
            {item.name} {item.surname}
          </StyledA>,
        ),
        email: _shapeData(item.email),
        phone: _shapeData(item.phone),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        register_state: _shapeData(
          item.register_state === 'new' ? __('Nový') : __('Aktivovaný'),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  async fetchData(parameters = {}) {
    console.log('ok');

    try {
      const { sorter, filters } = this.state;
      const { firm } = this.props;
      this.setState({ loading: true });

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      const newData = await API2.getRegisterCustomersAction(firm, params);
      const content = this.normalizeColumns(newData.items);

      console.log('newData---', newData);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  toggleRegisterCustomerForm() {
    this.setState({
      showRegisterForm: !this.state.showRegisterForm,
    });
  }

  async onSuccessfulCreate() {
    this.toggleRegisterCustomerForm();
    await this.fetchData();
  }

  renderControlBar() {
    const { history, firm } = this.props;
    const { showFilter, showRegisterForm } = this.state;

    return (
      <>
        <PopUpWrapper
          message={__('Nový zákazník')}
          display={showRegisterForm}
          onClose={() => this.toggleRegisterCustomerForm()}
          statusName={showRegisterForm}
          onEscClose={() => this.setState({ showRegisterForm: false })}
        >
          <RegisterCustomer
            firm={firm}
            successCallback={() => this.onSuccessfulCreate()}
          />
        </PopUpWrapper>
        <ControllBar
          history={history}
          name={__('Registrovaný zákazníci cez kasu')}
        >
          <ControlBarButton
            small
            primary
            basic
            icon="add-contact"
            onClick={() => this.toggleRegisterCustomerForm()}
          >
            {__('Registrovať nového zákazníka')}
          </ControlBarButton>
          <ControlBarButton
            onClick={() =>
              this.setState({
                showFilter: !showFilter,
              })
            }
            icon={showFilter ? 'arrow-right' : 'arrow-down'}
            name="show_filter"
          >
            {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
          </ControlBarButton>
        </ControllBar>
      </>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ContactsRegisterList);

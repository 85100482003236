import React from 'react';
import { isMoment } from 'moment';
import styled from 'styled-components';
import moment from 'moment-business-days';
import { Translation } from 'react-i18next';
import validator from 'validator';
import { extendMoment } from 'moment-range';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import { renderToString } from 'react-dom/server';
import Label from 'oxyrion-ui/lib/Label/Label';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { RouteLinkWraper, IFrame } from '../Components/ReusableComponents';
import MailLink from '../Components/MailLink';
import ExtendableSummary from '../Components/ExtendableSummary';
import API2 from '../API2';

export const managerRoles = ['managing_director', 'sales_director'];

const Moment = extendMoment(moment);

export const FORMAT_DATE_CHART = 'YYYYMMDD';
export const FORMAT_DATE_CHECK = 'YYYY-MM-DD';
export const FORMAT_HUMANE_DATE_DAY = 'dddd DD.MM.YYYY';
export const FORMAT_HUMANE_DATETIME = 'DD.MM.YYYY HH:mm';
export const FORMAT_HUMANE_DATE = 'DD.MM.YYYY';
export const FORMAT_HUMANE_TIME = 'HH.mm';
export const SK_PHONE_PREFIX = '+421';
export const CZ_PHONE_PREFIX = '+420';
export const LOCALE = ['sk-SK', 'cs-CZ'];

export const __e = text => {
  // vrati preklad ako react elemnt
  return (
    <Translation>
      {t => {
        return t(text) || `${text}`;
      }}
    </Translation>
  );
};

export const __ = text => {
  // vrati preklad ako string
  return renderToString(__e(text));
};

export const translateNewsletterStatus = status => {
  if (status === 'sent') {
    return __('Odoslané');
  }
  if (status === 'to_send') {
    return __('Nenaplánované');
  }

  if (status === 'scheduled') {
    return __('Naplánované');
  }

  return status;
};

export const formatPhoneNumber = (phone, firm) => {
  if (!phone) {
    return '';
  }

  if (phone.startsWith(0)) {
    const c = phone.split(' ').join('');
    const formatedPhone = c.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, '$2 $3 $4');
    switch (firm) {
      case 'SK':
        return `${SK_PHONE_PREFIX} ${formatedPhone}`;
      case 'CZ':
        return `${CZ_PHONE_PREFIX} ${formatedPhone}`;
      default:
        return phone;
    }
  }
  return phone;
};

export const parseHash = hash => {
  let content = hash;
  if (hash.indexOf('#') >= 0) {
    content = hash.substring(hash.indexOf('#') + 1);
  }
  const result = content.split('&').reduce((acum, item) => {
    const [key, value] = item.split('=');
    const r = Object.assign({}, acum);
    r[key] = value;
    return r;
  }, {});

  return result;
};

export const formatNumber = (n, c = 2, d = ',', t = ' ') => {
  const s = n < 0 ? '-' : '';
  const b = Math.abs(+n || 0);
  const i = String(parseInt(b.toFixed(c), 10));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(b - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
};

export const handleServiceStataus = status => {
  switch (status) {
    case 'new':
      return __('Nová');
    case 'solved':
      return __('Vyriešená');
    case 'confirmed':
      return __('Potvrdená');
    case 'closed':
      return __('Uzavretá');
    default:
      return status;
  }
};

export const formatPrice = (
  { amount, currency = 'EUR' },
  c = 2,
  d = ',',
  t = '\u00a0',
) => {
  if (currency === 'CZK') {
    return `${formatNumber(amount, c, d, t, c)}\u00a0Kč`;
  } else if (currency === 'EUR') {
    return `${formatNumber(amount, c, d, t, c)}\u00a0€`;
  }
  return `${formatNumber(amount, c, d, t, c)}\u00a0${currency}`;
};

export const redirect = redirectUrl => {
  window.location.href = redirectUrl;
};

export const handlePriceForTable = priceData => {
  if (!priceData) {
    return null;
  }
  return formatPrice(priceData);
};

export const formatDate = (date, format = FORMAT_HUMANE_DATE) => {
  return moment.isMoment(date) ? moment(date).format(format) : '';
};

export const getMonthByNumber = number => {
  switch (number) {
    case 1:
      return __('Január');
    case 2:
      return __('Február');
    case 3:
      return __('Marec');
    case 4:
      return __('Apríl');
    case 5:
      return __('Máj');
    case 6:
      return __('Jún');
    case 7:
      return __('Júl');
    case 8:
      return __('August');
    case 9:
      return __('September');
    case 10:
      return __('Október');
    case 11:
      return __('November');
    case 12:
      return __('December');
    default:
      return number;
  }
};

export const formatProbability = value => {
  switch (value) {
    case 'Low':
      return __('Nízka');
    case 'Medium':
      return __('Stredná');
    case 'High':
      return __('Vysoká');
    default:
      return value;
  }
};

export const toIsoDate = s => {
  return moment(s, FORMAT_HUMANE_DATE).toISOString();
};

export const renderLink = (to, name, noMargin = true, fontSize = rem(11)) => {
  return (
    <RouteLinkWraper fontSize={fontSize} noMargin={noMargin}>
      <Link to={to}>{name}</Link>
    </RouteLinkWraper>
  );
};

export const defaultCustomertype = values => {
  if (values && values.length) {
    return `${values[0].type} - ${values[0].subtype}`;
  }
  return '';
};

export const formatStatus = value => {
  switch (value) {
    case 'active':
      return __('Aktívny');
    case 'inactive':
      return __('Neaktívny');
    default:
      return value;
  }
};

export const normalizeAddressData = data => {
  if (!data) {
    return [];
  }

  const address = [
    {
      name: __('Krajina'),
      value: data.country,
    },
    {
      name: __('Mesto'),
      value: data.city,
    },
    {
      name: __('Ulica'),
      value: data.street,
    },
    {
      name: __('Poštové číslo'),
      value: data.postal_code,
    },
  ];
  return address;
};

export const getAddressLabel = data => {
  if (data) {
    return `${data.street && `${data.street}, `}${data.postal_code &&
      data.postal_code}`;
  }
  return '';
};

export const normalizeFirmInfoData = (
  data,
  handleNameOnClick = undefined,
  path,
) => {
  if (!data) {
    return [];
  }

  const firmInfo = [
    {
      name: __('Meno'),
      value: path
        ? renderLink(`${path}/${data._id}`, data.name, true)
        : data.name,
      handleOnClick: handleNameOnClick && (() => handleNameOnClick(data._id)),
    },
    {
      name: __('Email'),
      Component: () => <MailLink mail={data.email} />,
    },
    {
      name: __('IČO'),
      value: data.registration_no,
    },
    {
      name: __('IČ DPH'),
      value: data.vat_nr,
    },
    {
      name: __('Vlastník'),
      value: data.ownership
        ? data.ownership.map(o => o.display_name).join(', ')
        : '-',
    },
    {
      name: __('Kategória segmentu'),
      value: data.segment_category,
      hidden: true,
    },
    {
      name: __('Popis'),
      value: data.description,
      hidden: false,
    },
    {
      name: __('Hospodárske výsledky'),
      value: data.finStat,
      hidden: true,
    },
  ];
  return firmInfo;
};

export const normalizeCustomerBasicInfo = (data, address) => {
  const addressData = !address ? [] : normalizeAddressData(address);
  const basicData = !data ? [] : normalizeFirmInfoData(data, null, null);
  return [
    ...basicData.filter(
      f =>
        f.name !== __('IČO') &&
        f.name !== __('IČ DPH') &&
        f.name !== __('Kategória segmentu') &&
        f.name !== __('Popis') &&
        f.name !== __('Hospodárske výsledky'),
    ),
    ...addressData,
    ...basicData.filter(f => f.name === __('IČO')),
    ...basicData.filter(f => f.name === __('IČ DPH')),
    ...basicData.filter(f => f.name === __('Kategória segmentu')),
    ...basicData.filter(f => f.name === __('Popis')),
    ...basicData.filter(f => f.name === __('Hospodárske výsledky')),
  ];
};

export const normalizeCampaigns = (campaigns, handleCampaignOnClick, path) => {
  if (!campaigns) {
    return [];
  }
  return campaigns.reduce((acumulator, item) => {
    const row = [
      {
        value: item.name,
        handleOnClick: handleCampaignOnClick
          ? () => handleCampaignOnClick(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: item.campaign_alternative,
      },
      {
        value: formatDate(moment(item.valid_from), FORMAT_HUMANE_DATE),
      },
      {
        value: formatDate(moment(item.valid_to), FORMAT_HUMANE_DATE),
      },
      {
        value: formatDate(moment(item.publish_from), FORMAT_HUMANE_DATE),
      },
      {
        value: formatDate(moment(item.publish_to), FORMAT_HUMANE_DATE),
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};

export const normalizeOpportunities = (
  opportunities,
  handleOpportunityOnClick,
  path,
) => {
  if (!opportunities) {
    return [];
  }
  return opportunities.reduce((acumulator, item) => {
    const row = [
      {
        value: item.topic,
        handleOnClick: handleOpportunityOnClick
          ? () => handleOpportunityOnClick(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: formatOpportunityStatus(item.status),
      },
      {
        value: formatDate(item.actual_date),
      },
      {
        value: formatPrice(item.actual_profit),
      },
      {
        value: formatDate(item.estimated_date),
      },
      {
        value: formatPrice(item.estimated_profit),
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};

export const normalizeActivities = (
  activities,
  handleActivityOnClcik = null,
  path = null,
) => {
  if (!activities) {
    return [];
  }

  return activities.reduce((acumulator, item) => {
    const row = [
      {
        value: moment(item.to_date).format(FORMAT_HUMANE_DATETIME),
      },
      {
        value: item.subject,
        handleOnClick: handleActivityOnClcik
          ? () => handleActivityOnClcik(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: item.user_name || '_',
      },
      {
        value: <ExtendableSummary text={item.summary} />,
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};

export const normalizeTasks = (tasks, handleTaskOnClick, path) => {
  if (!tasks) {
    return [];
  }
  return tasks.reduce((acumulator, item) => {
    const row = [
      {
        value: formatDate(item.date),
      },
      {
        value: item.subject,
        handleOnClick: handleTaskOnClick
          ? () => handleTaskOnClick(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: moment(item.from_date).format(FORMAT_HUMANE_DATE),
      },
      {
        value: moment(item.to_date).format(FORMAT_HUMANE_DATE),
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};

export const normalizeContactInfoData = (
  data,
  firm = 'CZ',
  changeSubscribeTo,
  b2bRoles = [],
) => {
  if (!data) {
    return [];
  }

  return [
    {
      name: __('Meno'),
      value: data.name,
    },
    {
      name: __('Priezvisko'),
      value: data.surname,
    },
    {
      name: __('Pracovná pozícia'),
      value: data.position,
    },
    {
      name: __('Email'),
      Component: () => <MailLink mail={data.email} />,
    },
    {
      name: __('Mobil'),
      value: data.mobile,
    },
    {
      name: __('Telefón'),
      value: formatPhoneNumber(data.phone, firm),
    },
    {
      name: __('Web'),
      value: data.web,
    },
    {
      name: __('Vlastník'),
      value: data.ownership
        ? data.ownership.map(e => e.display_name).join(', ')
        : '-',
    },
    {
      name: __('Rola v portáli'),
      value:
        data.b2b_credentials &&
        b2bRoles.find(b => b.value === data.b2b_credentials.role)
          ? b2bRoles.find(b => b.value === data.b2b_credentials.role).label
          : '-',
    },
    {
      name: __('Súhlasí s odoberaním noviniek'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            onChange={() =>
              changeSubscribeTo(
                !data.newsletter_subscribe
                  ? true
                  : !data.newsletter_subscribe.status,
              )
            }
            checked={
              data.newsletter_subscribe && data.newsletter_subscribe.status
            }
          />
          {data.newsletter_subscribe && (
            <Label>{`od ${formatDate(
              moment(data.newsletter_subscribe.updated_date),
              FORMAT_HUMANE_DATETIME,
            )}`}</Label>
          )}
        </div>
      ),
    },
  ];
};

export const normalizeContactsPersonalData = data => {
  if (!data) {
    return [];
  }
  return [
    {
      name: __('Pohlavie'),
      value:
        (data.sex === 'M' && __('Muž')) || (data.sex === 'F' && __('Žena')),
    },
    {
      name: __('Narodeniny'),
      value:
        data.birthdate &&
        formatDate(
          !isMoment(data.birthdate) ? moment(data.birthdate) : data.birthdate,
        ),
    },
  ];
};

export const formatActivityBody = body => {
  const match = /<body>\r\n(.*)/;
  return body.match(match) ? body.match(match)[1] : '';
};

export const formatImportance = importance => {
  switch (importance) {
    case 'low':
      return __('Nízka');
    case 'normal':
      return __('Normálna');
    case 'high':
      return __('Vysoká');
    default:
      return importance;
  }
};

export const injectStyleToIframeBody = (styles, htmlContent) => {
  if (htmlContent) {
    const match = htmlContent.match('<body>');

    if (match) {
      const array = htmlContent.split('');
      array.splice(match.index + 5, 0, ` style="${styles}"`);
      return array.join('');
    }
  } else {
    return htmlContent;
  }
  return htmlContent;
};

export const normalizeCalendarEventData = data => {
  if (!data) {
    return [];
  }

  return [
    {
      name: __('Názov'),
      value: data.subject,
    },
    {
      name: __('Popis'),
      Component: () => (
        <React.Fragment>
          <IFrame
            title="body"
            srcDoc={injectStyleToIframeBody(
              `font-size: ${rem(11)}; font-family: ${
                baseTheme.fontFamily
              }; margin: ${rem(5)} 0; color: ${baseTheme.textColor}`,
              data.body,
            )}
          />
        </React.Fragment>
      ),
    },
    {
      name: __('od'),
      value: data.from_date
        ? moment(data.from_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Do'),
      value: data.to_date
        ? moment(data.to_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Priorita'),
      value: formatImportance(data.priority),
    },
    {
      name: __('Zrušená'),
      value: data.is_cancelled ? __('áno') : __('nie'),
    },
    {
      name: __('Vytvorená'),
      value: data.created_date
        ? moment(data.created_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Upravená'),
      value: data.updated_date
        ? moment(data.updated_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Zákazník'),
      value: data.customer_name,
    },
  ];
};

export const normalizeActivityData = data => {
  if (!data) {
    return [];
  }

  return [
    {
      name: __('Názov'),
      value: data.subject,
    },
    {
      name: __('Popis'),
      value: data.body,
    },
    {
      name: __('od'),
      value: data.from_date
        ? moment(data.from_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Do'),
      value: data.to_date
        ? moment(data.to_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Priorita'),
      value: formatImportance(data.priority),
    },
    {
      name: __('Zrušená'),
      value: data.is_cancelled ? __('áno') : __('nie'),
    },
    {
      name: __('Vytvorená'),
      value: data.created_date
        ? moment(data.created_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Upravená'),
      value: data.updated_date
        ? moment(data.updated_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Zákazník'),
      value: data.customer_name,
    },
  ];
};

export const normalizeTaskData = data => {
  if (!data) {
    return [];
  }

  return [
    {
      name: __('Názov'),
      value: data.subject,
    },
    {
      name: __('Dôležitosť'),
      value: formatImportance(data.importance),
    },
    {
      name: __('Od'),
      value: data.start_date
        ? moment(data.start_date).format(FORMAT_HUMANE_DATE)
        : '',
    },
    {
      name: __('Do'),
      value: data.due_date
        ? moment(data.due_date).format(FORMAT_HUMANE_DATE)
        : '',
    },
    {
      name: __('Vytvorená'),
      value: data.created_date
        ? moment(data.created_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Upravená'),
      value: data.updated_date
        ? moment(data.updated_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Dokončená'),
      value: data.completed_date
        ? moment(data.completed_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Zákazník'),
      value: data.customer_name,
    },
  ];
};

export const normalizeLeads = (leads, path) => {
  if (!leads) {
    return [];
  }
  return leads.map(item => [
    {
      value: item.name,
      path: `${path}${item._id}`,
    },
    {
      value: item.estimated_revenue ? formatPrice(item.estimated_revenue) : '',
    },
    {
      value: formatProbability(item.probability),
    },
    {
      value: item.revenue ? formatPrice(item.revenue) : '',
    },
    {
      value: item.profit ? formatPrice(item.profit) : '',
    },
    {
      value: item.billing_address ? item.billing_address.city : '',
    },
    {
      value:
        item.crm_type && item.crm_type.length
          ? defaultCustomertype(item.crm_type)
          : '',
    },
  ]);
};

export const normalizeReportLeads = (data, handleServiceOnClick, path) => {
  if (!data) {
    return [];
  }
  return data.map(item => [
    {
      value: item.date ? formatDate(moment(item.date), FORMAT_DATE_CHECK) : '',
      date: item.date
        ? formatDate(moment(item.date), FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      value:
        item.customer && item.customer.display_name
          ? item.customer.display_name
          : '',
      path: path ? `${path}/${item.customer._id}` : null,
      handleOnClick: handleServiceOnClick
        ? () => handleServiceOnClick(item._id)
        : null,
    },
    {
      value: item.description ? item.description : '',
      text: true,
    },
    {
      value:
        item.estimated_revenue && item.estimated_revenue.amount
          ? formatPrice({
              amount: item.estimated_revenue.amount,
              currency: item.estimated_revenue.currency,
            })
          : '',
    },
  ]);
};

export const normalizeReportOpportunities = (
  data,
  handleServiceOnClick,
  path,
) => {
  if (!data) {
    return [];
  }
  return data.map(item => [
    {
      value: item.date ? formatDate(moment(item.date), FORMAT_DATE_CHECK) : '',
      date: item.date
        ? formatDate(moment(item.date), FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      value:
        item.customer && item.customer.display_name
          ? item.customer.display_name
          : '',
      path: path ? `${path}/${item.customer._id}` : null,
      handleOnClick: handleServiceOnClick
        ? () => handleServiceOnClick(item._id)
        : null,
    },
    {
      value: item.topic ? item.topic : '',
    },
    {
      value:
        item.estimated_profit && item.estimated_profit.amount
          ? formatPrice({
              amount: item.estimated_profit.amount,
              currency: item.estimated_profit.currency,
            })
          : '',
    },
  ]);
};

export const normalizeReportActivity = (data, handleServiceOnClick, path) => {
  if (!data) {
    return [];
  }
  return data.map(item => [
    {
      value: item.date ? formatDate(moment(item.date), FORMAT_DATE_CHECK) : '',
      date: item.date
        ? formatDate(moment(item.date), FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      value:
        item.customer && item.customer.display_name
          ? item.customer.display_name
          : '',
      path:
        path && item.customer && item.customer._id
          ? `${path}/${item.customer._id}`
          : null,
      handleOnClick: handleServiceOnClick
        ? () => handleServiceOnClick(item._id)
        : null,
    },
    {
      value: item.subject ? item.subject : '',
    },
    {
      value: item.summary ? item.summary : '',
    },
  ]);
};

export const normalizeServices = (services, handleServiceOnClick, path) => {
  if (!services) {
    return [];
  }

  return services.reduce((acumulator, item) => {
    const row = [
      {
        value: formatDate(item.date),
      },
      {
        value: item.name,
        handleOnClick: handleServiceOnClick
          ? () => handleServiceOnClick(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: item.user_name || __('Nezadaný'),
      },
      {
        value: handleServiceStataus(item.status),
      },
      {
        value: item.updated_date
          ? formatDate(item.updated_date, FORMAT_HUMANE_DATETIME)
          : '',
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};
export const getContactFullName = contact => {
  if (contact) {
    const restNames = [];
    if (contact.name && contact.name !== '') {
      restNames.push(contact.name);
    }
    if (contact.surname && contact.surname !== '') {
      restNames.push(contact.surname);
    }
    return restNames.join(' ');
  }
  return null;
};

export const isEmptyValidation = {
  validate: value => validator.isEmpty(String(value)),
  validWhen: false,
  errorMessage: __('Nevyplnená hodnota'),
};

export const isEmailValidation = {
  validate: value => validator.isEmail(value),
  validWhen: true,
  errorMessage: __('Nesprávne zadaný email'),
};

export const isNotNullEmailValidation = {
  validate: value =>
    !validator.isEmpty(value) ? validator.isEmail(value) : true,
  validWhen: true,
  errorMessage: __('Nesprávne zadaný email'),
};

export const isPhoneValidation = {
  validate: value =>
    validator.isMobilePhone(value, LOCALE, { strictMode: true }),
  validWhen: true,
  errorMessage: __('Nesprávne zadané telefónne čislo'),
};

export const isNotNullPhoneValidation = {
  validate: value =>
    !validator.isEmpty(value)
      ? validator.isMobilePhone(value, LOCALE, { strictMode: true })
      : true,
  validWhen: true,
  errorMessage: __('Nesprávne zadané telefónne čislo'),
};

// format DD.MM.YYYY
export const isDateValidation = {
  validate: value =>
    value.match(
      /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/,
    ),
  validWhen: !null,
  errorMessage: __('Nesprávne zadaný dátum'),
};

export const isMomentDateValidation = {
  validate: value => value && value.isValid(),
  validWhen: true,
  errorMessage: __('Nesprávne zadaný dátum'),
};

// format DD.MM.YYYY
export const isNotNullDateValidation = {
  validate: value =>
    !validator.isEmpty(value)
      ? value.match(
          /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/,
        )
      : true,
  validWhen: !null,
  errorMessage: __('Nesprávne zadaný dátum'),
};

export const isNumberValidation = {
  validate: value => validator.isNumeric(String(value)),
  validWhen: true,
  errorMessage: __('Nesprávne zadaná číselná hodnota'),
};

export const isNumberGreaterThanZeroValidation = {
  validate: value => validator.isNumeric(String(value)) && value > 0,
  validWhen: true,
  errorMessage: __('Nesprávne zadaná číselná hodnota'),
};

export const validateFormItems = items => {
  const newErrors = [];
  const data = items.map(item => {
    if (!item.required && (item.value === '' || item.value === null)) {
      return { ...item, error: false };
    }
    if (item.required && (item.value === '' || item.value === null)) {
      if (!newErrors.includes(__('Nevyplnená hodnota'))) {
        newErrors.push(__('Nevyplnená hodnota'));
      }
      return { ...item, error: true };
    }
    if (item.validation) {
      const e = item.validation.reduce((acumulator, validators) => {
        if (!validators.validate(item.value) === validators.validWhen) {
          acumulator.push(validators.errorMessage);
          return acumulator;
        }
        return acumulator;
      }, []);

      if (e.length) {
        for (let i = 0; i < e.length; i++) {
          if (!newErrors.includes(e[i])) {
            newErrors.push(e[i]);
          }
        }
        return { ...item, error: true };
      }
      return { ...item, error: false };
    }
    return item;
  });
  return [newErrors, data];
};

export const getFirmPrice = (firm, value) => {
  const d = {
    amount: value ? Number(value) : 0,
  };
  if (firm === 'CZ') {
    return Object.assign({}, d, { currency: 'CZK' });
  }

  return Object.assign({}, d, { currency: 'EUR' });
};

export const buildQuery = query => {
  const keys = Object.keys(query);
  const queryItems = keys.reduce((acum, key) => {
    const q = `${key}=${query[key]}`;
    acum.push(q);
    return acum;
  }, []);
  return queryItems.join('&');
};

export const parseQuery = query => {
  let q;
  if (query.startsWith('?')) {
    q = query.slice(1);
  } else {
    q = query;
  }
  const separated = q.split('&');
  const result = separated.reduce((acum, item) => {
    const splitted = item.split('=');
    if (!splitted.length || splitted.length !== 2) {
      return acum;
    }
    const [name, value] = splitted;
    return { ...acum, [name]: value };
  }, {});

  return result;
};

export const getRangeOfDates = (
  start,
  end,
  key = 'day',
  format = 'YYYY-MM-DD',
) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  if (endMoment.diff(startMoment) < 0) return [];

  return Array.from(Moment.range(startMoment, endMoment).by(key)).map(date =>
    date.format(format),
  );
};

export const generateWeek = (date, events = []) => {
  const day = moment(date);
  const startDate = moment(day.startOf('isoWeek'));
  const endDate = moment(day.endOf('isoWeek'));

  return getRangeOfDates(startDate, endDate).reduce((acum, item) => {
    return { ...acum, [item]: events[item] || [] };
  }, {});
};

export const handleRedirect = url => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else if (url.startsWith('www')) {
    return `http://${url}`;
  }
  return url;
};

export const handleLoadOptions = (query = '', callback) => {
  if (query !== '') {
    return callback();
  }
  return new Promise(resolve => resolve({ options: [] }));
};

export const replaceObjectInArray = (
  data,
  value,
  identificator = '_id',
  valueIdentificator = '_id',
) => {
  return data.map(i => {
    if (i[identificator] === value[valueIdentificator]) {
      return value;
    }
    return i;
  });
};

export const capitalizeFirstletter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatPriceForApi = (price = {}) => {
  return {
    amount: Number(price.amount) || null,
    currency: price.currency || null,
  };
};

export const goBack = async history => {
  if (window.history.length) {
    history.goBack();
  } else {
    history.push('../calendar');
  }
};

export const removeFirmFromUrl = pathName => {
  const url = `/${pathName
    .slice(1)
    .split('/')
    .slice(1)
    .join('/')}`;
  return url;
};

export const getLastPartOfUrl = url => {
  return url.substring(url.lastIndexOf('/') + 1);
};

export const findInRecents = (pathname, recents) => {
  const keys = Object.keys(recents);
  for (let i = 0; i < keys.length; i++) {
    if (pathname.match(keys[i])) {
      return recents[keys[i]];
    }
  }
  return null;
};

export const formatOpportunityStatus = status => {
  switch (status) {
    case 'won':
      return __('Získaná');
    case 'lost':
      return __('Stratená');
    case 'new':
      return __('Nová');
    case 'canceled':
      return __('Zrušená');
    default:
      return '';
  }
};

export const getIdFromProps = props => {
  if (props.match.params.id) {
    return props.match.params.id;
  }
  return null;
};

export const getPercentOf = (x, y, toFixed = 3) => {
  if (x && y) {
    return ((x / y) * 100).toFixed(toFixed);
  }

  return 0;
};

export const roleDecoder = role => {
  return role;
};

export const getYearFromDate = date => {
  const dateObject = new Date(date);
  if (typeof dateObject.getFullYear === 'function') {
    return dateObject.getFullYear();
  }
  return null;
};

export const formatPaymentTerms = terms => {
  if (!terms) {
    return terms;
  }

  if (terms.startsWith('P')) {
    return `${terms.substr(1)} ${__('dní')}`;
  }
  if (terms === 'DOB') {
    return __('Dobierka');
  }
  if (terms === 'HOT') {
    return __('Hotovosť');
  }
  return terms;
};

export const formatActivityType = type => {
  switch (type) {
    case 'call':
      return __('Telefonát');
    case 'mail':
      return __('Email');
    case 'task':
      return __('Úloha');
    default:
      return __('Stretnutie');
  }
};

export const formatActivityStatus = status => {
  switch (status) {
    case 'waitingOnOthers':
      return __('Čakajúca');
    case 'completed':
      return __('Hotová');
    default:
      return __('Otvorená');
  }
};

export const getAvailableYears = () => {
  const years = [];
  for (let y = 2018; y <= new Date().getFullYear(); y++) {
    years.push(y);
  }
  return years;
};

export function dateRound(date, duration, method) {
  return moment(Math[method](+date / +duration) * +duration);
}

export const getYears = (from = 2010, to = 2019) => {
  const years = [];
  let year = from;
  while (year <= to) {
    years.push(year);
    year += 1;
  }

  return years;
};

export const reportStateTranslate = state => {
  if (state === 'closed') {
    return __('Uzavretý');
  } else if (state === 'open') {
    return __('Otvorený');
  }
  return state;
};

export const formatCurrencyByFirm = firm => {
  if (firm === 'CZ') {
    return `Kč`;
  } else if (firm === 'SK') {
    return `€`;
  }
  return `${firm}`;
};

export const translateCampaignStatus = (status, dateTo) => {
  let transStatus = status || '';
  if (status === 'approved') {
    transStatus = __('Schválená');
  } else if (status === 'rejected') {
    transStatus = __('Zamietnutá');
  } else if (status === 'pending') {
    transStatus = __('Čaká na schvalenie');
  } else if (status === 'for_approval') {
    transStatus = __('Na schválenie');
  }

  if (new Date() >= new Date(dateTo)) {
    transStatus = `${transStatus} - ${__('Ukončená')}`;
  }

  return transStatus;
};

export const translateAvailability = text => {
  if (text === 'available') {
    return __('Dostupné');
  } else if (text === 'part-available') {
    return __('Čiastočne dostupné');
  } else if (text === 'not-available') {
    return __('Momentálne nedostupné');
  }

  return text;
};

export const translate = (lang, translations, defaultValue) => {
  if (
    translations &&
    translations.find(t => t.lang === lang) &&
    translations.find(t => t.lang === lang).value.length > 0
  ) {
    return translations.find(t => t.lang === lang).value;
  }
  return defaultValue;
};

export const translateCodelist = (lang, translations, defaultValue) => {
  if (
    translations &&
    translations.find(t => t.lang === lang) &&
    translations.find(t => t.lang === lang).label &&
    translations.find(t => t.lang === lang).label.length > 0
  ) {
    return translations.find(t => t.lang === lang).label;
  }
  return defaultValue;
};

export const translateLayer = layer => {
  if (layer === 0) {
    return __('Impregnácia');
  } else if (layer === 1) {
    return __('1. vrstva');
  } else if (layer === 2) {
    return __('2. vrstva');
  } else if (layer === 3) {
    return __('3. vrstva');
  } else if (layer === 4) {
    return __('4. vrstva');
  }

  return layer;
};

export const getColorForBrand = brand => {
  if (brand === 'ANZA') {
    return '#000000';
  } else if (brand === 'BIOCOMP') {
    return '#005b2a';
  } else if (brand === 'CAR-REP') {
    return '#595959';
  } else if (brand === 'DUNAMENTI') {
    return '#2f9f44';
  } else if (brand === 'DURIERU') {
    return '#63b8d4';
  } else if (brand === 'FINKOLORA') {
    return '#d1e19e';
  } else if (brand === 'IRIS') {
    return '#d0cece';
  } else if (brand === 'JEDYNKA') {
    return '#002876';
  } else if (brand === 'KIILTO') {
    return '#32428b';
  } else if (brand === 'MASTON') {
    return '#0059a1';
  } else if (brand === 'MIRKA') {
    return '#fbb929';
  } else if (brand === 'NEOCOLORI') {
    return '#d8a427';
  } else if (brand === 'OWATROL') {
    return '#b40001';
  } else if (brand === 'PIGROL') {
    return '#feed04';
  } else if (brand === 'SHOP') {
    return '#525252';
  } else if (brand === 'TEKNOS IND. METAL') {
    return '#0f4194';
  } else if (brand === 'TEKNOS IND. WOOD') {
    return '#029650';
  } else if (brand === 'TIKKURILA DECO') {
    return '#e20019';
  } else if (brand === 'TIKKURILA IND. FLOOR') {
    return '#aeabab';
  } else if (brand === 'TIKKURILA IND. METAL') {
    return '#0045ad';
  } else if (brand === 'TIKKURILA IND. WOOD') {
    return '#bf9001';
  } else if (brand === 'ZORKA') {
    return '#d1112c';
  } else if (brand === 'INTERNAL ITEMS') {
    return '#8cd609';
  }

  return '#3a3838';
};

export const getColorByReceaivablesGroup = group => {
  if (group === 'Pred splatnosťou') {
    return '#009b3f';
  } else if (group === '0-30 dní') {
    return '#8cd609';
  } else if (group === '31-60 dní') {
    return '#fbb929';
  } else if (group === '61-90 dní') {
    return '#bf9001';
  } else if (group === 'nad 90 dní') {
    return '#e20019';
  }

  return undefined;
};

export const getNextWorkDay = (date, days = 1) => {
  if (moment.isMoment(date)) {
    return date.businessAdd(days, 'days');
  }
  return moment(date).businessAdd(days, 'days');
};

export const getOrderDeliveryDate = (time = '11:00') => {
  const m = moment();
  return m.isBefore(moment(time, 'HH:mm'))
    ? getNextWorkDay(m, 1)
    : getNextWorkDay(m, 2);
};

export const translateUnitOfMeasure = unit => {
  if (unit === 'PCS') {
    return __('Kusy');
  }

  if (unit === 'BOX') {
    return __('Balenie');
  }

  if (unit === 'PAL') {
    return __('Paleta');
  }

  return unit;
};

export const formatColorToRGB = color => {
  return `rgb(${color.R},${color.G},${color.B})`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (typeof bytes !== 'number') {
    return bytes;
  }
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const sumOfValuesInArrayOfObjects = (items, prop) => {
  return items.reduce((a, b) => {
    return a + b[prop];
  }, 0);
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const translateUserPosition = role => {
  if (role === 'managing_director') {
    return __('Generálny riaditeľ');
  }
  if (role === 'marketing_manager') {
    return __('Marketingový manažér');
  }
  if (role === 'sales_representative') {
    return __('Obchodný zástupca');
  }
  if (role === 'sales_director') {
    return __('Obchodný riaditeľ');
  }
  if (role === 'sales_manager') {
    return __('Manažér predaja');
  }
  if (role === 'sales_assistant') {
    return __('Asisten predaja');
  }
  if (role === 'service_technician') {
    return __('Servisný technik');
  }
  if (role === 'service_technician') {
    return __('Aplikačný technik');
  }
  if (role === 'logistic_manager') {
    return __('Manažér logistiky');
  }
  if (role === 'it_manager') {
    return __('IT manažér');
  }
  if (role === 'marketing_assistant') {
    return __('Asisten marketingu');
  }

  if (role === 'customer') {
    return __('Zákazník');
  }

  return role;
};

export const normalizeSectionConfig = (newCrmSettings, config, user, firm) => {
  const isUserManager = managerRoles.includes(
    user.companies.find(c => c.companyId === firm) &&
      user.companies.find(c => c.companyId === firm).role,
  );

  if (!newCrmSettings) {
    const modifiedConfig = Object.assign({}, config);
    Object.keys(config).forEach(key => {
      modifiedConfig[key].filters = config[key].filters.filter(
        f => isUserManager || !f.onlyManager,
      );
    });

    return config;
  }

  const modifiedConfig = {};
  Object.keys(config).forEach(key => {
    const apiConfigItem = newCrmSettings.find(n => n.key === key);

    if (!apiConfigItem) {
      modifiedConfig[key] = config[key];
    } else {
      modifiedConfig[key] = Object.assign(config[key], {
        filters: config[key].filters
          .filter(f => isUserManager || !f.onlyManager)
          .map(f => {
            const apiConfigFilter = apiConfigItem.filters.find(
              a => a.filterValue === f.filterValue,
            );

            if (apiConfigFilter) {
              if (apiConfigFilter.filterValue === 'year') {
                apiConfigFilter.default = f.default;
                apiConfigFilter.values = f.values;
              }
              apiConfigFilter.values = f.values;
              return apiConfigFilter;
            }

            return f;
          }),
      });
    }
  });

  return modifiedConfig;
};

export const getSellEmailByFirm = firm => {
  if (firm === 'SK') {
    return 'predaj@dejmark.com';
  } else if (firm === 'CZ') {
    return 'prodej@dejmark.com';
  }

  return '';
};

export const getServiceStatus = service => {
  if (service.status === 'closed') {
    return __('Uzavretá');
  }

  if (!service.solver_id) {
    return __('Nová');
  }

  return __('V riešení');
};

const Bold = styled.b`
  font-weight: ${props => props.weight};
`;

const Normal = styled.b`
  font-weight: ${props => props.weight};
`;

export const getHighlightedText = (
  text,
  highlight,
  normalWeight = 400,
  highlightedWeight = 800,
) => {
  // Split text on highlight term, include term itself into parts, ignore case

  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <span>
      {parts.map(part =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <Bold weight={highlightedWeight}>{part}</Bold>
        ) : (
          <Normal weight={normalWeight}>{part}</Normal>
        ),
      )}
    </span>
  );
};

export const marketingTranslate = (lang, translations = [], defaultValue) => {
  const translation = translations.find(t => t.lang === lang);

  if (translation && translation.value.length > 0) {
    return translation.value;
  }
  return defaultValue;
};

export const fetchCodelistByQuery = async (query, codelistName) => {
  try {
    return await API2.getCodelistAction(codelistName).then(res => {
      return {
        options: res.codelist
          .filter(c => c.label.toUpperCase().includes(query.toUpperCase()))
          .map(item => ({
            value: item.value || item._id,
            label: item.label,
          })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const renderLinkOnNewTab = (
  to,
  name,
  noMargin = true,
  fontSize = rem(11),
) => {
  return (
    <RouteLinkWraper fontSize={fontSize} noMargin={noMargin}>
      {/* eslint-disable-next-line */}
      <a target="_blank" href={to}>
        {name}
      </a>
    </RouteLinkWraper>
  );
};

export const getCurrencyByFirm = currency => {
  if (currency === 'SK') {
    return '€';
  } else if (currency === 'CZ') {
    return 'CZK';
  } else if (currency === 'EN') {
    return '£';
  }

  return currency;
};

export const LabelComp = styled.div`
  height: ${props => (props.big ? rem(48) : rem(24))};
  width: ${props => (props.big ? rem(300) : rem(140))};
  border-radius: ${rem(12)};
  font-size: ${props => (props.big ? rem(14) : rem(12))};
  margin-left: ${props => props.big && rem(8)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.background};
  border: ${props =>
    props.borderColor && `${rem(2)} solid ${props.borderColor}`};
`;

export const translateClaimState = state => {
  if (state === 'new') {
    return __('Nová');
  }

  if (state === 'waiting_for_merchandise') {
    return __('Čaká sa na tovar');
  }

  if (state === 'in_process') {
    return __('V riešení');
  }

  if (state === 'finished') {
    return __('Ukončená');
  }

  if (state === 'cancelled') {
    return __('Zrušená');
  }

  return state;
};

export const translateClaimType = type => {
  if (type === 'return') {
    return __('Vrátenie');
  }

  if (type === 'claim') {
    return __('Reklamácia');
  }

  return type;
};
export const translateClaimSolutions = type => {
  if (type === 'restitution') {
    return __('Vrátenie peňazí');
  }

  if (type === 'new_merchandise') {
    return __('Výmena za nový produkt');
  }
  if (type === 'fix_merchandise') {
    return __('Oprava produktu');
  }

  return type;
};
export const translateClaimResult = type => {
  if (type === 'accepted') {
    return __('Akceptovaná');
  }

  if (type === 'rejected') {
    return __('Zamietnutá');
  }

  return type;
};

export const translatePaymentState = state => {
  if (state === 'paid') {
    return __('Zaplatená');
  }

  if (state === 'not-paid') {
    return __('Nezaplatená');
  }

  if (state === 'waiting') {
    return __('Čaká na zaplatenie');
  }

  if (state === 'cash_on_delivery') {
    return __('Pri vyzdvihnutí');
  }

  if (state === 'timeouted') {
    return __('Čas vypršal');
  }

  return state;
};

export const getTypeOfClaimLabel = claim => {
  return translateClaimType(claim);
};
export const getStateLabel = state => {
  if (state === 'new') {
    return translateClaimState(state);
  }
  if (state === 'waiting_for_merchandise') {
    return translateClaimState(state);
  }
  if (state === 'in_process') {
    return translateClaimState(state);
  }
  if (state === 'finished') {
    return translateClaimState(state);
  }
  if (state === 'cancelled') {
    return translateClaimState(state);
  }
  return state;
};
export const getPaymentStateLabel = state => {
  if (state === 'paid') {
    return (
      <LabelComp background="#C1D375">{translatePaymentState(state)}</LabelComp>
    );
  }

  if (state === 'not-paid') {
    return (
      <LabelComp background="#EF476F">{translatePaymentState(state)}</LabelComp>
    );
  }

  if (state === 'waiting') {
    return (
      <LabelComp background="#E0D26E">{translatePaymentState(state)}</LabelComp>
    );
  }

  if (state === 'cash_on_delivery') {
    return (
      <LabelComp background="#ADB5BD">{translatePaymentState(state)}</LabelComp>
    );
  }

  if (state === 'timeouted') {
    return (
      <LabelComp background="#EF476F">{translatePaymentState(state)}</LabelComp>
    );
  }

  return state;
};

export const transledOrderState = state => {
  if (state === 'Open') {
    return __('Nová');
  }

  if (state === 'Pending_Approval') {
    return __('Pripravuje sa');
  }

  if (state === 'Pending_Prepayment') {
    return __('Zabalená');
  }

  if (state === 'Sent' || state === 'Released') {
    return __('Odoslaná');
  }

  if (state === 'Canceled') {
    return __('Zrušená');
  }

  return state;
};

export const transledEomOrderState = state => {
  switch (state) {
    case 'new':
      return __('Nová');
    case 'preparing':
      return __('Pripravuje sa');
    case 'ready_for_pickup':
      return __('Pripravená na vyzdvihnutie');
    case 'delivered':
      return __('Doručená');
    case 'in_delivery':
      return __('V preprave');
    case 'finished':
      return __('Dokončená');
    case 'canceled':
      return __('Stornovaná');
    default:
      return __(state);
  }
};

export const getOrderStateLabel = state => {
  if (state === 'Open') {
    return (
      <LabelComp borderColor="#FFD166">{transledOrderState(state)}</LabelComp>
    );
  }

  if (state === 'Pending_Approval') {
    return (
      <LabelComp borderColor="#E0D26E">{transledOrderState(state)}</LabelComp>
    );
  }

  if (state === 'Pending_Prepayment') {
    return (
      <LabelComp borderColor="#C1D375">{transledOrderState(state)}</LabelComp>
    );
  }

  if (state === 'Sent' || state === 'Released') {
    return (
      <LabelComp borderColor="#06D6A0">{transledOrderState(state)}</LabelComp>
    );
  }
  if (state === 'Canceled') {
    return (
      <LabelComp borderColor="#EF476F">{transledOrderState(state)}</LabelComp>
    );
  }

  return state;
};

export const getOrderEomStateLabel = (state, big = false) => {
  if (state === 'new') {
    return (
      <LabelComp big={big} background="#ffe500">
        {transledEomOrderState(state)}
      </LabelComp>
    );
  }

  if (state === 'preparing') {
    return (
      <LabelComp big={big} background="#ffa300">
        {transledEomOrderState(state)}
      </LabelComp>
    );
  }

  if (state === 'ready_for_pickup') {
    return (
      <LabelComp big={big} background="#C1D375">
        {transledEomOrderState(state)}
      </LabelComp>
    );
  }

  if (state === 'in_delivery') {
    return (
      <LabelComp big={big} background="#1fadd6">
        {transledEomOrderState(state)}
      </LabelComp>
    );
  }

  if (state === 'delivered') {
    return (
      <LabelComp big={big} background="#06d64b">
        {transledEomOrderState(state)}
      </LabelComp>
    );
  }

  if (state === 'finished') {
    return (
      <LabelComp big={big} background="#06D6A0">
        {transledEomOrderState(state)}
      </LabelComp>
    );
  }

  if (state === 'canceled') {
    return (
      <LabelComp big={big} background="#EF476F">
        {transledEomOrderState(state)}
      </LabelComp>
    );
  }

  return state;
};

export const getEndDaysLabel = days => {
  if (days <= 0) {
    return <span style={{ color: '#434245' }}>{__('Neplynie')}</span>;
  }
  if (days <= 1) {
    return (
      <span style={{ color: '#ef5278' }}>
        {days} {__('dní')}
      </span>
    );
  }
  if (days <= 14) {
    return (
      <span style={{ color: '#ca9312' }}>
        {days} {__('dní')}
      </span>
    );
  }
  return (
    <span style={{ color: '#0b0b0b' }}>
      {days} {__('dní')}
    </span>
  );
};

export const getDaysOfCurrentWeek = () => {
  const currentDate = moment();

  const days = [];

  const weekStart = currentDate.clone().startOf('isoWeek');
  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days'));
  }

  return days;
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const phoneNumberValidation = (firm, number) => {
  if (!number) {
    return false;
  }

  if (firm === 'SK') {
    const re = /^(\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/;
    return re.test(String(number.trim()));
  }
  const re = /^(\+?420)(2[0-9]{2}|3[0-9]{2}|4[0-9]{2}|5[0-9]{2}|72[0-9]|73[0-9]|77[0-9]|60[1-8]|56[0-9]|70[2-5]|79[0-9])[0-9]{3}[0-9]{3}$/;
  return re.test(String(number.trim()));
};

export const getApprovalStatus = status => {
  if (status === 'waiting') {
    return 'Nová recenzia';
  }

  if (status === 'approved') {
    return 'Schválené';
  }

  if (status === 'declined') return 'Zamietnuté';
};

export const renderBillState = state => {
  if (state === 'ERROR') {
    return (
      <LabelComp big={true} background="#EF476F">
        {__('Chyba pri odosielaní do navision')}
      </LabelComp>
    );
  }

  if (state === 'OK') {
    return (
      <LabelComp big={true} background="#06D6A0">
        {__('Zaučtovaný v navision')}
      </LabelComp>
    );
  }

  if (state === 'NAV_VOID') {
    return (
      <LabelComp big={true} background="#ffa300">
        {__('Nezaučtovaný v nav')}
      </LabelComp>
    );
  }
};

export const paymentsTypes = code => {
  if (
    code === 'HOTOVOST' ||
    code === 'SHO_MT3' ||
    code === 'SHO_MT1' ||
    code === 'SHO_MT2'
  ) {
    return __('Hotovosť');
  }
  if (code === 'KARTA') {
    return __('Kartou');
  }
  if (code === 'KOMBINOVANA') {
    return __('Kombinovaná');
  }
  return code;
};

export const getEshopProductUrl = (firm, productUrl) => {
  switch (firm) {
    case 'SK':
      return `https://xfarby.sk/produkt/${productUrl}`;
    case 'CZ':
      return `https://xbarvy.cz/produkt/${productUrl}`;
    default:
      return `https://xfarby.sk/produkt/${productUrl}`;
  }
};

export const translatePacketaStatus = (statusCode, firm = 'SK') => {
  const translations = {
    EN: {
      1: 'We have received the packet data. Freshly created packet.',
      2: 'Packet has been accepted at our branch.',
      3: 'Packet is waiting to be dispatched from branch branchId to branch destinationBranchId.',
      4: 'Packet is on the way from branch branchId to branch destinationBranchId.',
      5: 'Packet has been delivered to its destination (branchId), the customer has been informed via SMS.',
      6: "Packet has been handed over to an external carrier for delivery. It can be traced via carrier's tracking application under externalTrackingCode.",
      7: 'Packet was picked up by the customer at the branch branchId.',
      9: 'Packet is on the way back to the sender.',
      10: 'Packet has been returned to the sender.',
      11: 'Packet has been cancelled.',
      12: 'Packet has been collected and is on its way to the depot branchId.',
      14: 'Customs declaration process.',
      15: 'Reverse packet has been accepted at our branch.',
      16: 'Packeta Home made unsuccessful delivery attempt of packet.',
      17: 'Packeta Home delivery attempt of packet ends in rejected by recipient response.',
      999: 'Unknown packet status.',
    },
    SK: {
      1: 'Dáta zásielky boli prijaté. Čerstvo vytvorená zásielka',
      2: 'Balíček bol prijatý na našej pobočke',
      3: 'Balíček čaká na odoslanie z pobočky',
      4: 'Balíček je na ceste z pobočky do svojej destinácie',
      5: 'Balíček bol doručený na svoju destináciu. Zákazník bol informovaný prostredníctvom SMS',
      6: 'Balíček bol odovzdaný externému dopravcovi na doručenie',
      7: 'Zákazník si balíček prevzal na pobočke',
      9: 'Balíček je na ceste späť k odosielateľovi',
      10: 'Balíček bol vrátený odosielateľovi',
      11: 'Balíček bol zrušený',
      12: 'Balíček bol vybraný a je na ceste do depa',
      14: 'Proces colnej deklarácie',
      15: 'Obrátený balíček bol prijatý na našej pobočke',
      16: 'Neuspešný pokus o doručenie na adresu',
      17: 'Pokus o doručenie na adresu skončil odmietnutím',
      999: 'Neznámy stav balíčka',
    },
    CZ: {
      1: 'Data zasílky byla přijata. Čerstvě vytvořená zásilka',
      2: 'Balíček byl přijat na naší pobočce',
      3: 'Balíček čeká na expedici z pobočky',
      4: 'Balíček je na cestě z pobočky do své destinace',
      5: 'Balíček byl doručen na svou destinaci. Zákazník byl informován prostřednictvím SMS',
      6: 'Balíček byl předán externímu dopravci k doručení',
      7: 'Zákazník si balíček vyzvedl na pobočce',
      9: 'Balíček je na cestě zpět k odesílateli',
      10: 'Balíček byl vrácen odesílateli',
      11: 'Balíček byl zrušen',
      12: 'Balíček byl vybrán a je na cestě do depa',
      14: 'Proces celního prohlášení',
      15: 'Obrácený balíček byl přijat na naší pobočce',
      16: 'Neuspěšný pokus o doručení na adresu',
      17: 'Pokus o doručení na adresu skončil odmítnutím',
      999: 'Neznámý stav balíčku',
    },
  };

  return translations[firm][statusCode] || '-';
};

/* eslint-disable */
import 'isomorphic-fetch';

class Api {
  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName,
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.status === 204) return response;

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          }
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Set Headers
   * @method
   * @param headers - array of headers
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
   * Authorize the AD user over MS AD API
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.redirectUri -
   * @param {} parameters.appId -
   * @param {} parameters.scopes -
   * @param {} parameters.state -
   * @param {} parameters.loginHint -
   * @param {} parameters.extraRedirect -
   */
  oauthAuthorizeAdAction(parameters = {}) {
    let path = '/oauth/authorize/ad';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'text/html');
    headers.append('Accept', 'application/json');

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw Error('Missing required parameter: redirectUri');
    }

    if (typeof parameters['appId'] !== 'undefined') {
      queryParameters['app_id'] = parameters['appId'];
    }

    queryParameters['app_id'] = this.transformParameter(
      queryParameters['app_id'],
    );

    if (typeof parameters['appId'] === 'undefined') {
      throw Error('Missing required parameter: appId');
    }

    if (typeof parameters['scopes'] !== 'undefined') {
      queryParameters['scopes'] = parameters['scopes'];
    }

    queryParameters['scopes'] = this.transformParameter(
      queryParameters['scopes'],
    );

    if (typeof parameters['scopes'] === 'undefined') {
      throw Error('Missing required parameter: scopes');
    }

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['loginHint'] !== 'undefined') {
      queryParameters['login_hint'] = parameters['loginHint'];
    }

    queryParameters['login_hint'] = this.transformParameter(
      queryParameters['login_hint'],
    );

    if (typeof parameters['extraRedirect'] !== 'undefined') {
      queryParameters['extra_redirect'] = parameters['extraRedirect'];
    }

    queryParameters['extra_redirect'] = this.transformParameter(
      queryParameters['extra_redirect'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.code - Code from MS AD service
   * @param {} parameters.state - State which was sent to MS AD service to identify action
   * @param {} parameters.error - Error identifier in case of trouble
   * @param {} parameters.errorDescription - Error description in case of trouble
   */
  oauthCallbackAdAction(parameters = {}) {
    let path = '/v1/oauth/callback/ad';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'text/html');
    headers.append('Accept', 'application/json');

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['error'] !== 'undefined') {
      queryParameters['error'] = parameters['error'];
    }

    queryParameters['error'] = this.transformParameter(
      queryParameters['error'],
    );

    if (typeof parameters['errorDescription'] !== 'undefined') {
      queryParameters['error_description'] = parameters['errorDescription'];
    }

    queryParameters['error_description'] = this.transformParameter(
      queryParameters['error_description'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Authorize B2B user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.state -
   * @param {} parameters.body -
   */
  oauthAuthorizeB2BAction(parameters = {}) {
    let path = '/oauth/authorize/b2b';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'text/html');
    headers.append('Accept', 'application/json');

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Authorize B2B user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.state -
   * @param {} parameters.body -
   */
  oauthAuthorizeB2BMobileAction(parameters = {}) {
    let path = '/oauth/authorize/b2b-mobile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get password reset token info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} passwordToken -
   */
  oauthB2bRestorePasswordInfo(passwordToken, parameters = {}) {
    let path = '/oauth/b2b/restore-password/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{passwordToken}', passwordToken);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Send request for email restoration.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} passwordToken -
   * @param {} parameters.body -
   */
  oauthB2bRestorePassword(passwordToken, parameters = {}) {
    let path = '/oauth/b2b/restore-password/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{passwordToken}', passwordToken);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Send request for email restoration
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  oauthB2bRestorePasswordRequest(parameters = {}) {
    let path = '/oauth/b2b/restore-password';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Register XColor user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postOauthRegistration(parameters = {}) {
    let path = '/registration';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Send request for email restoration.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  oauthXPaintRestorePasswordRequest(parameters = {}) {
    let path = '/oauth/xPaint/restore-password';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get token informations.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  oauthTokenInfoAction(parameters = {}) {
    let path = '/oauth/tokeninfo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get informations about user.

     * @method
     * @param {object} parameters - method options and parameters
     */
  meAction(parameters = {}) {
    let path = '/me';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get informations about B2B user.

     * @method
     * @param {object} parameters - method options and parameters
     */
  b2bMeAction(parameters = {}) {
    let path = '/b2b/me';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get B2B user settings
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BMeSettingsAction(parameters = {}) {
    let path = '/b2b/me/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update b2b address.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putB2BMeSettingsAction(parameters = {}) {
    let path = '/b2b/me/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get user acl list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  getUserAcl(companyId, parameters = {}) {
    let path = '/crm/{companyId}/user-acl';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get user acl list.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BUserAcl(parameters = {}) {
    let path = '/b2b/user-acl';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Change logged user token.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} userId - ID of user
   */
  oauthLoggedUserChange(userId, parameters = {}) {
    let path = '/oauth/change-user/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of leads.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.userId - 
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.sorter - Define sort order
       * @param {} parameters.owner - 
       * @param {} parameters.active - 
       * @param {} parameters.q - 
     */
  getLeadsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/leads';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['userId'] = parameters['userId'];
    }

    queryParameters['userId'] = this.transformParameter(
      queryParameters['userId'],
    );

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['sorter'] = 'name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['owner'] !== 'undefined') {
      queryParameters['owner'] = parameters['owner'];
    }

    queryParameters['owner'] = this.transformParameter(
      queryParameters['owner'],
    );

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new lead.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postLeadsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/leads';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete lead.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  deleteLeadAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/leads/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Leads summary per year.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.userId - 
       * @param {} companyId - Company identifier
       * @param {} parameters.year - Year
     */
  getLeadsSummaryAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/leads-summary';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['userId'] = parameters['userId'];
    }

    queryParameters['userId'] = this.transformParameter(
      queryParameters['userId'],
    );

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create lead ownership change request
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} parameters.body -
   */
  postLeadOwnershipChangeRequest(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/leads/{customerId}/ownershipRequest';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create lead ownership change request
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} status - Customer id for which must be data loaded
   */
  putLeadOwnershipChangeRequest(
    companyId,
    customerId,
    status,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/leads/{customerId}/ownershipRequest/{status}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{status}', status);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List top customers.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.userId - 
       * @param {} companyId - Company identifier
       * @param {} parameters.year - Year
       * @param {} parameters.month - Month
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.cummulativeTo - Date
     */
  getTopCustomersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/top-customers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['userId'] = parameters['userId'];
    }

    queryParameters['userId'] = this.transformParameter(
      queryParameters['userId'],
    );

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['cummulativeTo'] !== 'undefined') {
      queryParameters['cummulativeTo'] = parameters['cummulativeTo'];
    }

    queryParameters['cummulativeTo'] = this.transformParameter(
      queryParameters['cummulativeTo'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List turnover of shares by brand.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.userId - 
       * @param {} companyId - Company identifier
       * @param {} parameters.year - Year
       * @param {} parameters.cummulativeTo - Date
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getTurnoverByBrandAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/turnover-by-brand';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['userId'] = parameters['userId'];
    }

    queryParameters['userId'] = this.transformParameter(
      queryParameters['userId'],
    );

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['cummulativeTo'] !== 'undefined') {
      queryParameters['cummulativeTo'] = parameters['cummulativeTo'];
    }

    queryParameters['cummulativeTo'] = this.transformParameter(
      queryParameters['cummulativeTo'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List turnover of shares by brand.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.year - Year
     */
  getB2BTurnoverByBrandAction(parameters = {}) {
    let path = '/b2b/turnover-by-brand';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List plans for logged user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.userId - 
       * @param {} companyId - Company identifier
       * @param {} parameters.year - Year
       * @param {} parameters.cummulativeTo - Date
     */
  getTurnoverPlanAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/turnover-plan';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['userId'] = parameters['userId'];
    }

    queryParameters['userId'] = this.transformParameter(
      queryParameters['userId'],
    );

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['cummulativeTo'] !== 'undefined') {
      queryParameters['cummulativeTo'] = parameters['cummulativeTo'];
    }

    queryParameters['cummulativeTo'] = this.transformParameter(
      queryParameters['cummulativeTo'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List plans for logged user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.year - Year
     */
  getB2BTurnoverPlanAction(parameters = {}) {
    let path = '/b2b/turnover-plan';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Discounts list for logged user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.year - Year
     */
  getB2BDiscountsAction(parameters = {}) {
    let path = '/b2b/discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get B2B customers machines.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BCustomerMachinesAction(parameters = {}) {
    let path = '/b2b/machines';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return list of actual bonuses.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   */
  getBonusesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/bonuses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return list of actual bonuses.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   */
  getB2BBonusesAction(parameters = {}) {
    let path = '/b2b/bonuses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return fees list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.type -
   */
  getServiceFeesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/fees';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get role permisions map.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getRolesPermissionsMap(parameters = {}) {
    let path = '/crm/menu-permisions-map';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get role permisions map.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getB2BRolesPermissionsMap(parameters = {}) {
    let path = '/b2b/menu-permisions-map';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get menu for user
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BUserMenuItems(parameters = {}) {
    let path = '/b2b/user-menu-items';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of opportunities.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.sorter - Define sort order
   * @param {} parameters.onlyOwned -
   * @param {} parameters.owner -
   * @param {} parameters.status - Available values: new, won, lost, cancelled, completed, notCompleted
   * @param {} parameters.q -
   */
  getOpportunitiesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters['onlyOwned'] = true;

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    if (typeof parameters['owner'] !== 'undefined') {
      queryParameters['owner'] = parameters['owner'];
    }

    queryParameters['owner'] = this.transformParameter(
      queryParameters['owner'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Opportunities summary per year.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.userId - 
       * @param {} companyId - Company identifier
       * @param {} parameters.year - Year
     */
  getOpportunitiesSummaryAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities-summary';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['userId'] = parameters['userId'];
    }

    queryParameters['userId'] = this.transformParameter(
      queryParameters['userId'],
    );

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail of opportunity.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} opportunityId - ID of opprotunity
   */
  getOpportunityAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Updates opportunity.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} opportunityId - ID of opprotunity
       * @param {} parameters.body - 
     */
  putOpportunityAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete opportunity.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} opportunityId - ID of opprotunity
   */
  deleteOpportunityAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Upload Opportunity photo.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} opportunityId - ID of opprotunity
   * @param {} parameters.body -
   */
  postOpportunityPhotoAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete Opportunity photo.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} opportunityId - ID of opprotunity
   * @param {} fullPath - Escaped full path to photo
   */
  deleteOpportunityPhotoAction(
    companyId,
    opportunityId,
    fullPath,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/opportunities/{opportunityId}/photos/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Receivables list for logged user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.year - Year
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - Define sort order
     */
  getReceivablesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/receivables';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = '-total';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Receivables list for logged user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.year - Year
     */
  getB2BReceivablesAction(parameters = {}) {
    let path = '/b2b/receivables';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Receivables detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} receivableId - 
     */
  getReceivableAction(companyId, receivableId, parameters = {}) {
    let path = '/crm/{companyId}/receivables/{receivableId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Receivables per period.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.year - Year
       * @param {} parameters.userId - 
     */
  getReceivablesSummaryAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/receivables-summary';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['userId'] = parameters['userId'];
    }

    queryParameters['userId'] = this.transformParameter(
      queryParameters['userId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Receivables download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} receivableId - 
       * @param {} parameters.type - 
     */
  getReceivableDownloadAction(companyId, receivableId, parameters = {}) {
    let path = '/crm/{companyId}/receivables/{receivableId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Invoice for receivable.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} receivableId - 
       * @param {} parameters.year - Year
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - Define sort order
     */
  getReceivableInvoicesAction(companyId, receivableId, parameters = {}) {
    let path =
      '/crm/{companyId}/receivables/{receivableId}/invoices-descriptions';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = '-due_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get file to download by downloadId
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} downloadId -
   */
  getDownloaderAction(downloadId, parameters = {}) {
    let path = '/downloader/{download_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{download_id}', downloadId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Generate EAN13 image from code in Base64
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.eanCode - EAN code
   */
  getEANImageAction(parameters = {}) {
    let path = '/crm/ean/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['eanCode'] !== 'undefined') {
      queryParameters['EANCode'] = parameters['eanCode'];
    }

    queryParameters['EANCode'] = this.transformParameter(
      queryParameters['EANCode'],
    );

    if (typeof parameters['eanCode'] === 'undefined') {
      throw Error('Missing required parameter: eanCode');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Generate EAN13 code
   * @method
   * @param {object} parameters - method options and parameters
   */
  getGenerateEANAction(parameters = {}) {
    let path = '/crm/ean/generate-code';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return catalog products for CRM
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   * @param {} parameters.customerId -
   */
  getCatalogProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return catalog products for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   * @param {} parameters.canBeToned -
   * @param {} parameters.getFullLimit -
   */
  getLiteCatalogProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products-lite';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['canBeToned'] !== 'undefined') {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    queryParameters['canBeToned'] = this.transformParameter(
      queryParameters['canBeToned'],
    );

    if (typeof parameters['getFullLimit'] !== 'undefined') {
      queryParameters['getFullLimit'] = parameters['getFullLimit'];
    }

    queryParameters['getFullLimit'] = this.transformParameter(
      queryParameters['getFullLimit'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of catalog product for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} productId - ID of product
   * @param {} parameters.customerId -
   */
  getCatalogProductAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Documentation of catalog product for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} productId - ID of product
   */
  getCatalogProductDocumentationAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Variants of catalog product for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} productId - ID of product
   */
  getCatalogProductVariantsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/variants';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Variants of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} productId - ID of product
   */
  getB2BCatalogProductVariantsAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/variants';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Price recalculation with custom shadeId for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} productId - ID of product
   * @param {} customShadeId - Custom shade ID
   * @param {} parameters.toningSystemId -
   * @param {} parameters.customerId -
   * @param {} parameters.base -
   */
  getCatalogProductCustomShadePricesAction(
    companyId,
    productId,
    customShadeId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/catalog-products-custom-shade/{productId}/prices/{customShadeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    path = path.replace('{customShadeId}', customShadeId);

    if (typeof parameters['toningSystemId'] !== 'undefined') {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    queryParameters['toningSystemId'] = this.transformParameter(
      queryParameters['toningSystemId'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['base'] !== 'undefined') {
      queryParameters['base'] = parameters['base'];
    }

    queryParameters['base'] = this.transformParameter(queryParameters['base']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Filter for catalog products
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.productTypes -
   */
  getCatalogFiltersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products-filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['productTypes'] !== 'undefined') {
      queryParameters['productTypes'] = parameters['productTypes'];
    }

    queryParameters['productTypes'] = this.transformParameter(
      queryParameters['productTypes'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Filter for catalog products
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.productTypes -
   */
  getB2BCatalogFiltersAction(parameters = {}) {
    let path = '/b2b/catalog-products-filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['productTypes'] !== 'undefined') {
      queryParameters['productTypes'] = parameters['productTypes'];
    }

    queryParameters['productTypes'] = this.transformParameter(
      queryParameters['productTypes'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Price recalculation with shadeId for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} productId - ID of product
   * @param {} parameters.shadeId -
   * @param {} parameters.colorCardId -
   * @param {} parameters.toningSystemId -
   * @param {} parameters.customerId -
   * @param {} parameters.base -
   * @param {} parameters.applyDiscounts -
   */
  getCatalogProductPricesAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/prices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['shadeId'] !== 'undefined') {
      queryParameters['shadeId'] = parameters['shadeId'];
    }

    queryParameters['shadeId'] = this.transformParameter(
      queryParameters['shadeId'],
    );

    if (typeof parameters['colorCardId'] !== 'undefined') {
      queryParameters['colorCardId'] = parameters['colorCardId'];
    }

    queryParameters['colorCardId'] = this.transformParameter(
      queryParameters['colorCardId'],
    );

    if (typeof parameters['toningSystemId'] !== 'undefined') {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    queryParameters['toningSystemId'] = this.transformParameter(
      queryParameters['toningSystemId'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['base'] !== 'undefined') {
      queryParameters['base'] = parameters['base'];
    }

    queryParameters['base'] = this.transformParameter(queryParameters['base']);

    if (typeof parameters['applyDiscounts'] !== 'undefined') {
      queryParameters['applyDiscounts'] = parameters['applyDiscounts'];
    }

    queryParameters['applyDiscounts'] = this.transformParameter(
      queryParameters['applyDiscounts'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Price recalculation with shadeId for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} productId - ID of product
   * @param {} parameters.shadeId -
   * @param {} parameters.colorCardId -
   * @param {} parameters.toningSystemId -
   * @param {} parameters.customerId -
   * @param {} parameters.base -
   * @param {} parameters.applyDiscounts -
   */
  getB2BCatalogProductPricesAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/prices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['shadeId'] !== 'undefined') {
      queryParameters['shadeId'] = parameters['shadeId'];
    }

    queryParameters['shadeId'] = this.transformParameter(
      queryParameters['shadeId'],
    );

    if (typeof parameters['colorCardId'] !== 'undefined') {
      queryParameters['colorCardId'] = parameters['colorCardId'];
    }

    queryParameters['colorCardId'] = this.transformParameter(
      queryParameters['colorCardId'],
    );

    if (typeof parameters['toningSystemId'] !== 'undefined') {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    queryParameters['toningSystemId'] = this.transformParameter(
      queryParameters['toningSystemId'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['base'] !== 'undefined') {
      queryParameters['base'] = parameters['base'];
    }

    queryParameters['base'] = this.transformParameter(queryParameters['base']);

    if (typeof parameters['applyDiscounts'] !== 'undefined') {
      queryParameters['applyDiscounts'] = parameters['applyDiscounts'];
    }

    queryParameters['applyDiscounts'] = this.transformParameter(
      queryParameters['applyDiscounts'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Filter for catalog products
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  getCatalogCombinedFiltersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/combined-filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Filter for catalog products
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BCatalogCombinedFiltersAction(parameters = {}) {
    let path = '/b2b/combined-filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Download catalog product document.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} productId - ID of product
       * @param {} documentationId - DocumentationId
     */
  getProductCatalogDocumnetationFileAction(
    companyId,
    productId,
    documentationId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/catalog-products/{productId}/documentation/{documentationId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    path = path.replace('{documentationId}', documentationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return catalog products for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.customerId -
   * @param {} parameters.filters -
   * @param {} parameters.canBeToned -
   */
  getUltraLiteCatalogProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products-ultra-lite';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['canBeToned'] !== 'undefined') {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    queryParameters['canBeToned'] = this.transformParameter(
      queryParameters['canBeToned'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return catalog products for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   * @param {} parameters.canBeToned -
   * @param {} parameters.categoryName - Category of publication name
   */
  getUltraLiteB2BCatalogProductsAction(parameters = {}) {
    let path = '/b2b/catalog-products-ultra-lite';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['canBeToned'] !== 'undefined') {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    queryParameters['canBeToned'] = this.transformParameter(
      queryParameters['canBeToned'],
    );

    if (typeof parameters['categoryName'] !== 'undefined') {
      queryParameters['categoryName'] = parameters['categoryName'];
    }

    queryParameters['categoryName'] = this.transformParameter(
      queryParameters['categoryName'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Search products.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   */
  searchProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products-search';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return catalog products for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   * @param {} parameters.canBeToned -
   * @param {} parameters.categoryName - Category of publication name
   */
  getLiteB2BCatalogProductsAction(parameters = {}) {
    let path = '/b2b/catalog-products-lite';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['canBeToned'] !== 'undefined') {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    queryParameters['canBeToned'] = this.transformParameter(
      queryParameters['canBeToned'],
    );

    if (typeof parameters['categoryName'] !== 'undefined') {
      queryParameters['categoryName'] = parameters['categoryName'];
    }

    queryParameters['categoryName'] = this.transformParameter(
      queryParameters['categoryName'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of shades of catalog products.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} productId - ID of product
   * @param {} parameters.colorcardId -
   * @param {} parameters.q -
   * @param {} parameters.limit - Limit for paginated results
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.onlyFinishedUsedShades -
   */
  getCatalogProductShadesAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/shades';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['colorcardId'] !== 'undefined') {
      queryParameters['colorcardId'] = parameters['colorcardId'];
    }

    queryParameters['colorcardId'] = this.transformParameter(
      queryParameters['colorcardId'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['onlyFinishedUsedShades'] !== 'undefined') {
      queryParameters['onlyFinishedUsedShades'] =
        parameters['onlyFinishedUsedShades'];
    }

    queryParameters['onlyFinishedUsedShades'] = this.transformParameter(
      queryParameters['onlyFinishedUsedShades'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of defined colorcards

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - Define sort order
     */
  getCatalogProductInspirationsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * References of catalog product for CRM

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - Define sort order
     */
  getCatalogProductReferencesAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/references';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Return paint systems of catalog product for CRM

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.filters - 
     */
  getCatalogProductPaintSystemsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return tintable types of catalog products.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  getTintingTypesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/tinting-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Download catalog product with toning prices for specified colorcard

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} productId - ID of product
       * @param {} colorcardId - Colorcard ID
     */
  getProductCatalogTintingPricesFileAction(
    companyId,
    productId,
    colorcardId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/catalog-products/{productId}/colorcard/{colorcardId}/tinting-prices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    path = path.replace('{colorcardId}', colorcardId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Download catalog product reviews from google.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} catalogProductId - ID of catalog product
       * @param {} parameters.limit - 
       * @param {} parameters.body - 
     */
  postGoogleReviewDownload(catalogProductId, parameters = {}) {
    let path = '/catalog-product/{catalogProductId}/google-reviews-download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{catalogProductId}', catalogProductId);

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of shades of catalog products.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} productId - ID of product
   * @param {} parameters.colorcardId -
   * @param {} parameters.q -
   * @param {} parameters.limit - Limit for paginated results
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.onlyFinishedUsedShades -
   */
  getB2BCatalogProductShadesAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/shades';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['colorcardId'] !== 'undefined') {
      queryParameters['colorcardId'] = parameters['colorcardId'];
    }

    queryParameters['colorcardId'] = this.transformParameter(
      queryParameters['colorcardId'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['onlyFinishedUsedShades'] !== 'undefined') {
      queryParameters['onlyFinishedUsedShades'] =
        parameters['onlyFinishedUsedShades'];
    }

    queryParameters['onlyFinishedUsedShades'] = this.transformParameter(
      queryParameters['onlyFinishedUsedShades'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return tintable types of catalog products.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BTintingTypesAction(parameters = {}) {
    let path = '/b2b/tinting-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Vouchers for customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getB2BCustomerVouchersAction(parameters = {}) {
    let path = '/b2b/vouchers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get b2b address.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getB2BAddresses(parameters = {}) {
    let path = '/b2b/address';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Post new b2b address.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postB2BAddress(parameters = {}) {
    let path = '/b2b/address';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Method for 2 orders merging.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartIdToMerge - cart ID to merge
       * @param {} cartId - ID of cart
     */
  putB2BOrderMergeAction(cartIdToMerge, cartId, parameters = {}) {
    let path = '/eshop/merge-orders/{cartId}/{cartIdToMerge}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cartIdToMerge}', cartIdToMerge);

    path = path.replace('{cartId}', cartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  getOrderSettings(parameters = {}) {
    let path = '/b2b/order-settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update for order settings.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putOrderSettings(parameters = {}) {
    let path = '/b2b/order-settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * B2B delivery types.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getB2BDeliveryTypesAction(parameters = {}) {
    let path = '/b2b/delivery-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * B2B payment.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getB2BPaymentTypesAction(parameters = {}) {
    let path = '/b2b/payment-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update b2b address.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} addressId - ID of customer address
       * @param {} parameters.body - 
     */
  putB2BAddress(addressId, parameters = {}) {
    let path = '/b2b/address/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{addressId}', addressId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove b2b address.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} addressId - ID of customer address
     */
  deleteB2BAddress(addressId, parameters = {}) {
    let path = '/b2b/address/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update b2b address.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} addressId - ID of customer address
     */
  putB2BAddress_1(addressId, parameters = {}) {
    let path = '/b2b/address-default/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * References of catalog product for B2B

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - Define sort order
     */
  getB2BCatalogProductReferencesAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/references';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Documentation of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} productId - ID of product
   */
  getB2BCatalogProductDocumentationAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of defined colorcards

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - Define sort order
     */
  getB2BCatalogProductInspirationsAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Return paint systems of catalog product for B2B

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.filters - 
     */
  getB2BCatalogProductPaintSystemsAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of references.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - Define sort order
       * @param {} parameters.onlyOwned - 
       * @param {} parameters.year - 
       * @param {} parameters.country - 
     */
  getUserReferencesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/references';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of inspirations.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - Define sort order
       * @param {} parameters.onlyOwned - 
     */
  getUserInspirationsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = 'created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get documentation files to download
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} documentationId - DocumentationId
   */
  getDocumentationDownloadAction(companyId, documentationId, parameters = {}) {
    let path = '/crm/{companyId}/documentation/{documentationId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{documentationId}', documentationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of documentations.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.type -
   * @param {} parameters.lang -
   * @param {} parameters.brand -
   * @param {} parameters.publishedLang -
   * @param {} parameters.publishedChanel -
   * @param {} parameters.productIds -
   * @param {} parameters.sorter -
   */
  getDocumentationAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    if (typeof parameters['publishedLang'] !== 'undefined') {
      queryParameters['publishedLang'] = parameters['publishedLang'];
    }

    queryParameters['publishedLang'] = this.transformParameter(
      queryParameters['publishedLang'],
    );

    if (typeof parameters['publishedLang'] === 'undefined') {
      throw Error('Missing required parameter: publishedLang');
    }

    if (typeof parameters['publishedChanel'] !== 'undefined') {
      queryParameters['publishedChanel'] = parameters['publishedChanel'];
    }

    queryParameters['publishedChanel'] = this.transformParameter(
      queryParameters['publishedChanel'],
    );

    if (typeof parameters['publishedChanel'] === 'undefined') {
      throw Error('Missing required parameter: publishedChanel');
    }

    if (typeof parameters['productIds'] !== 'undefined') {
      queryParameters['productIds'] = parameters['productIds'];
    }

    queryParameters['productIds'] = this.transformParameter(
      queryParameters['productIds'],
    );

    queryParameters['sorter'] = '-documentation.file_updated_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get documentation files to download
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} documentationId - DocumentationId
   */
  getB2BDocumentationDownloadAction(documentationId, parameters = {}) {
    let path = '/b2b/documentation/{documentationId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{documentationId}', documentationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of documentations.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.type -
   * @param {} parameters.lang -
   * @param {} parameters.brand -
   * @param {} parameters.productIds -
   * @param {} parameters.sorter -
   */
  getB2BDocumentationAction(parameters = {}) {
    let path = '/b2b/documentation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    if (typeof parameters['productIds'] !== 'undefined') {
      queryParameters['productIds'] = parameters['productIds'];
    }

    queryParameters['productIds'] = this.transformParameter(
      queryParameters['productIds'],
    );

    queryParameters['sorter'] = '-documentation.file_updated_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get dashboard widget types
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  getDashboardWidgetTypesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/dashboard/widget-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get dashboard user settings
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} userId -
   */
  getUserDashboardSettingsAction(companyId, userId, parameters = {}) {
    let path = '/crm/{companyId}/dashboard/user/{userId}/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update dashboard user settings
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} userId -
   * @param {} parameters.body -
   */
  putUserDashboardSettingsAction(companyId, userId, parameters = {}) {
    let path = '/crm/{companyId}/dashboard/user/{userId}/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create template.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postCalculationTableTemplateAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-table-template';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of calculation table templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.onlyOwned -
   */
  getCalculationTableTemplatesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-table-template';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of calculation table templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} templateId - ID of template
   */
  getCalculationTableTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update template.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} templateId - ID of template
       * @param {} parameters.body - 
     */
  putCalculationTableTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete calculation table template.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} templateId - ID of template
     */
  deleteCalculationTableTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update calculation table

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} calculationTableId - ID of calc. table
       * @param {} parameters.body - 
     */
  putCalculationTablesAction(companyId, calculationTableId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables/{calculationTableId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Detail of calculation table

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} calculationTableId - ID of calc. table
     */
  getCalculationTableAction(companyId, calculationTableId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables/{calculationTableId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update calculation table

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} calculationTableId - ID of calc. table
     */
  deleteCalculationTableAction(companyId, calculationTableId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables/{calculationTableId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create calculation table.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} calculationTableId - ID of calc. table
     */
  postCalculationTableCopyAction(
    companyId,
    calculationTableId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/copy-calculation/{calculationTableId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update calculation table

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} calculationTableId - ID of calc. table
     */
  getCalculatedCalculationTableAction(
    companyId,
    calculationTableId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/calculation-tables/{calculationTableId}/calculation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new calculation table

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postCalculationTablesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of calculation tables

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.customerId - 
       * @param {} parameters.opportunityId - 
       * @param {} parameters.q - 
       * @param {} parameters.status - 
       * @param {} parameters.sorter - Define sort order
     */
  getCalculationTablesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['opportunityId'] !== 'undefined') {
      queryParameters['opportunityId'] = parameters['opportunityId'];
    }

    queryParameters['opportunityId'] = this.transformParameter(
      queryParameters['opportunityId'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    queryParameters['sorter'] = 'name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new calculation table

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
     */
  postEmptyCalculationTableAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/empty-calculation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Calculation table PDF file to download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} calculationTableId - ID of calc. table
       * @param {} parameters.type - 
     */
  getCalculationTableDownloadAction(
    companyId,
    calculationTableId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/calculation-tables/{calculationTableId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters['type'] = 'pdf';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List user activities.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.type - Available values: task, call, mail, meeting, other
       * @param {} parameters.status - Available values: notStarted, inProgress, completed, waitingOnOthers, deferred, notCompleted, new
       * @param {} parameters.sorter - 
       * @param {} parameters.from - 
       * @param {} parameters.owner - 
       * @param {} parameters.to - 
       * @param {} parameters.onlyOwned - 
       * @param {} parameters.q - 
     */
  getUserActivitiesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['from'] !== 'undefined') {
      queryParameters['from'] = parameters['from'];
    }

    queryParameters['from'] = this.transformParameter(queryParameters['from']);

    if (typeof parameters['owner'] !== 'undefined') {
      queryParameters['owner'] = parameters['owner'];
    }

    queryParameters['owner'] = this.transformParameter(
      queryParameters['owner'],
    );

    if (typeof parameters['to'] !== 'undefined') {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters['to'] = this.transformParameter(queryParameters['to']);

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create user activity.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.forced - 
       * @param {} parameters.body - 
     */
  postUserActivityAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of formula

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} opportunityId - ID of opprotunity
     */
  getOpportunityActivitiesAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunies/{opportunityId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of user activity.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} activityId - ID of user activity

     */
  getUserActivityAction(companyId, activityId, parameters = {}) {
    let path = '/crm/{companyId}/activities/{activityId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{activityId}', activityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update user activity.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} activityId - ID of user activity

       * @param {} parameters.body - 
     */
  putUserActivityAction(companyId, activityId, parameters = {}) {
    let path = '/crm/{companyId}/activities/{activityId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{activityId}', activityId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete user activity.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} activityId - ID of user activity

     */
  deleteUserActivityAction(companyId, activityId, parameters = {}) {
    let path = '/crm/{companyId}/activities/{activityId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{activityId}', activityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update user activity.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  putUserActivitiesStatusAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities/status';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List user followed messages (tasks) from office365.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getUserOffice365TasksAction(parameters = {}) {
    let path = '/me/office365/tasks';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Detail Office365 task.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} taskId - Id of task
     */
  getUserOffice365TaskAction(taskId, parameters = {}) {
    let path = '/me/office365/tasks/{taskId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{taskId}', taskId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * CSV file user activities.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} userId - ID of user
       * @param {} parameters.type - Available values: task, call, mail, meeting, other
       * @param {} parameters.sorter - 
       * @param {} parameters.status - Available values: notStarted, inProgress, completed, waitingOnOthers, deferred, notCompleted, new
       * @param {} parameters.year - 
       * @param {} parameters.onlyOwned - 
       * @param {} parameters.q - 
     */
  getUserActivitiesCsvAction(companyId, userId, parameters = {}) {
    let path = '/crm/{companyId}/users/{userId}/activities-csv-export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters['onlyOwned'] = true;

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * "List of MS Graph Calendar events. There will be events from calendar with name 'CRM'. Few cases can occure:
- calendar with name 'CRM' not exists in MS graph - no events will be listed.
- calendar was removed from MS graph out of CRM - no events will be listed listed until new event will be created
- calendar was recreated outside of CRM - no event will be listed until new event will be created"

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.from - 
       * @param {} parameters.to - 
     */
  getUserCalendarAction(parameters = {}) {
    let path = '/me/calendar';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['from'] !== 'undefined') {
      queryParameters['from'] = parameters['from'];
    }

    queryParameters['from'] = this.transformParameter(queryParameters['from']);

    if (typeof parameters['to'] !== 'undefined') {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters['to'] = this.transformParameter(queryParameters['to']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * "Create new event in MS Graph calendar. When creating starts then this flow will be applied:
  - check if user object has stored ID of calendar with name 'CRM' in local DB
    * if ID exists then will be used to create event
    * if ID not exists, new calendar will be created, stored in local DB and used to create event
  - if request to create event returns error (no calendar exists) then new new calendar will be 
    created with name 'CRM', stored in local DB and used to create new event
  - otherwise evetn will be created"

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - The MS Graph event data.
     */
  postUserCalendarEventAction(parameters = {}) {
    let path = '/me/calendar';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail of MS Graph Calendar event.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} eventId - ID of event
   */
  getUserCalendarEventAction(eventId, parameters = {}) {
    let path = '/me/calendar/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{eventId}', eventId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update event in MS Graph calendar.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} eventId - ID of event
   * @param {} parameters.body - The MS Graph event data.
   */
  putUserCalendarEventAction(eventId, parameters = {}) {
    let path = '/me/calendar/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{eventId}', eventId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete event in MS Graph calendar.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} eventId - ID of event
   */
  deleteUserCalendarEventAction(eventId, parameters = {}) {
    let path = '/me/calendar/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{eventId}', eventId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get reports list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.sorter - 
       * @param {} parameters.type - 
       * @param {} parameters.year - 
       * @param {} parameters.owner - 
       * @param {} parameters.q - 
     */
  getReportsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/reports';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['sorter'] = 'name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['owner'] !== 'undefined') {
      queryParameters['owner'] = parameters['owner'];
    }

    queryParameters['owner'] = this.transformParameter(
      queryParameters['owner'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get business info of customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postReportAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/reports';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Report detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
     */
  getReportAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} parameters.body - 
     */
  putReportAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
     */
  deleteReportAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} parameters.status - 
       * @param {} parameters.body - 
     */
  putChangeReportStatusAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/status-change';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of tunover for actual and previous year.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} parameters.year - 
       * @param {} parameters.month - 
     */
  getReportMonthTurnoverAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/turnover';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of tunover by brand for actual and previous year.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} parameters.year - 
       * @param {} parameters.month - 
       * @param {} parameters.monthStats - 
     */
  getReportMonthTurnoverByBrandAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/turnover-by-brand';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    if (typeof parameters['monthStats'] !== 'undefined') {
      queryParameters['monthStats'] = parameters['monthStats'];
    }

    queryParameters['monthStats'] = this.transformParameter(
      queryParameters['monthStats'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer photos.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} parameters.year - 
       * @param {} parameters.month - 
     */
  getMonthActivitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of customers for report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.year - 
       * @param {} parameters.q - 
       * @param {} parameters.month - 
       * @param {} parameters.sorter - Define sort order
     */
  getReportCustomersAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/customers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters['sorter'] = 'turnover_actual';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Detail of customer for report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} customerId - Customer ID
       * @param {} parameters.year - 
       * @param {} parameters.month - 
     */
  getReportCustomerDetailAction(
    companyId,
    reportId,
    customerId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/reports/{reportId}/customers/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of user activities.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} customerId - Customer ID
       * @param {} parameters.year - 
       * @param {} parameters.month - 
     */
  getReportCustomerActivitiesAction(
    companyId,
    reportId,
    customerId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/customers/{customerId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of services releated to customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} customerId - Customer ID
       * @param {} parameters.year - 
       * @param {} parameters.month - 
     */
  getReportCustomerServicesAction(
    companyId,
    reportId,
    customerId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/customers/{customerId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer photos.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} customerId - Customer ID
       * @param {} parameters.year - 
       * @param {} parameters.month - 
     */
  getReportCustomerPhotosAction(
    companyId,
    reportId,
    customerId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/customers/{customerId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of opportunities for report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.year - 
       * @param {} parameters.month - 
       * @param {} parameters.q - 
     */
  getReportOpportunitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Detail of customer for report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} opportunityId - ID of opprotunity
       * @param {} parameters.year - 
       * @param {} parameters.month - 
     */
  getReportOpportunityDetailAction(
    companyId,
    reportId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/opportunities/{opportunityId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{opportunityId}', opportunityId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Detail of customer for report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} opportunityId - ID of opprotunity
     */
  getReportOpportunitiesActivitiesAction(
    companyId,
    reportId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/opportunities/{opportunityId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Opportunity photos.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} opportunityId - ID of opprotunity
     */
  getReportOpportunityPhotosAction(
    companyId,
    reportId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/opportunities/{opportunityId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create receivable description

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} taskId - Id of task
       * @param {} invoiceId - Id of invoice
       * @param {} parameters.body - 
     */
  putReceivableTaskStatusToFinishAction(
    companyId,
    reportId,
    taskId,
    invoiceId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/invoices/{invoiceId}/tasks/{taskId}/finish';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{taskId}', taskId);

    path = path.replace('{invoiceId}', invoiceId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create receivable description

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
       * @param {} parameters.body - 
     */
  postReceivableDescriptionsAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/invoices-descriptions';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update report.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
     */
  putUpdateReportReceivables(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/receivable-update';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get tasks.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter - Define sort order
   */
  getReporTasksAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/report-tasks';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = 'name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Set deleted flag on invoice in report
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} reportId - ID od report
   * @param {} invoiceId - Id of invoice
   */
  putReceivableDeleteInvoice(companyId, reportId, invoiceId, parameters = {}) {
    let path =
      '/crm/{companyId}/reports/{reportId}/receivable-delete-invoice/{invoiceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Week turnover of user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
     */
  getWeekTurnoverAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/turnover-week';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Week activities summary of user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
     */
  getWeekSummaryActivitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/activities-summary-week';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * All activities of user for current week.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
     */
  getUserWeekActivitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/user-week-activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * All opportunities of user for current week.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
     */
  getUserWeekOpportunitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/user-week-opportunities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Current week leads information.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} reportId - ID od report
     */
  getUserWeekLeadsAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/user-week-leads';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get turnover stats
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.year -
   * @param {} parameters.month -
   * @param {} parameters.userId -
   * @param {} parameters.customerId -
   * @param {} parameters.groupBy -
   */
  getTurnoverStatsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/turnover-stats';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['userId'] = parameters['userId'];
    }

    queryParameters['userId'] = this.transformParameter(
      queryParameters['userId'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['groupBy'] !== 'undefined') {
      queryParameters['groupBy'] = parameters['groupBy'];
    }

    queryParameters['groupBy'] = this.transformParameter(
      queryParameters['groupBy'],
    );

    if (typeof parameters['groupBy'] === 'undefined') {
      throw Error('Missing required parameter: groupBy');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List owned customers.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.year - Year
       * @param {} parameters.q - 
       * @param {} parameters.owner - 
       * @param {} parameters.sorter - Define sort order
       * @param {} parameters.active - 
       * @param {} parameters.allCustomers - 
     */
  getCustomersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['owner'] !== 'undefined') {
      queryParameters['owner'] = parameters['owner'];
    }

    queryParameters['owner'] = this.transformParameter(
      queryParameters['owner'],
    );

    queryParameters['sorter'] = 'name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    if (typeof parameters['allCustomers'] !== 'undefined') {
      queryParameters['allCustomers'] = parameters['allCustomers'];
    }

    queryParameters['allCustomers'] = this.transformParameter(
      queryParameters['allCustomers'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getCustomerAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer detail by registration no.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} email - Company identifier
     */
  getCustomerByEmailAction(companyId, email, parameters = {}) {
    let path = '/crm/{companyId}/customers-by-email/{email}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{email}', email);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer detail by registration no.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} registrationNo - Company identifier
     */
  getCustomerByRegistrationNoAction(
    companyId,
    registrationNo,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/customers-by-reg-no/{registrationNo}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{registrationNo}', registrationNo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of customer types.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
     */
  getCustomerTypes(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customer-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of customer contacts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getCustomerContactsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new contact.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - 
     */
  postCustomerContactAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Bonuses management.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - 
     */
  putCustomerBonusesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/bonuses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Batch state update of customers.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - 
     */
  putCustomersBusinessConditionsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/businessConditions/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get business info of customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getCustomersBusinessInfoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/business-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Turnover plan informations for specified customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.year - Year
     */
  getCustomerTurnoverPlanAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/turnover-plan';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Turnover informations by brand for specified customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.year - Year
     */
  getCustomerTurnoverByBrandAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/turnover-by-brand';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Receivables for customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.year - Year
     */
  getCustomerReceivablesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/receivables';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer photos.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getCustomerPhotosAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload customer photo.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - 
     */
  postCustomerPhotoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of sales for customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} parameters.active -
   */
  getCustomerSalesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/sales';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List customer of opportunities.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.status - Available values: new, won, lost, cancelled, completed, notCompleted
   * @param {} parameters.withCalculationTables -
   */
  getCustomerOpportunitiesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['withCalculationTables'] !== 'undefined') {
      queryParameters['withCalculationTables'] =
        parameters['withCalculationTables'];
    }

    queryParameters['withCalculationTables'] = this.transformParameter(
      queryParameters['withCalculationTables'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Creates new opportunity.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - 
     */
  postCustomerOpportunitiesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of customer's types.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getCustomerTypesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List customer activities.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.type - Available values: task, call, mail, meeting, other
     */
  getCustomerActivitiesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer videos.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getCustomerVideosAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/videos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload customer video.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - 
     */
  postCustomerVideoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/videos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of services releated to customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getCustomerHeldpeskServicesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of campaigns releated to customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.state - 
     */
  getCustomerCampaignsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/campaigns';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer softwares.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
     */
  getWarehouseSoftwareAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/warehouse-software';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Qualification customer request.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.forceNoDiscount - 
     */
  createQualificationRequest(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/qualification';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['forceNoDiscount'] !== 'undefined') {
      queryParameters['forceNoDiscount'] = parameters['forceNoDiscount'];
    }

    queryParameters['forceNoDiscount'] = this.transformParameter(
      queryParameters['forceNoDiscount'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete qualification operation.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  removeQualificationRequest(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/qualification';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Qualification operation.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} qualifyOperation - Qualification Operation
       * @param {} parameters.qualificationDeclineText - Reasons for rejection
     */
  gualifyCustomer(companyId, customerId, qualifyOperation, parameters = {}) {
    let path =
      '/crm/{companyId}/customers/{customerId}/qualification/{qualifyOperation}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{qualifyOperation}', qualifyOperation);

    if (typeof parameters['qualificationDeclineText'] !== 'undefined') {
      queryParameters['qualificationDeclineText'] =
        parameters['qualificationDeclineText'];
    }

    queryParameters['qualificationDeclineText'] = this.transformParameter(
      queryParameters['qualificationDeclineText'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete customer video.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} fullPath - Escaped full path to video
     */
  deleteCustomerVideoAction(companyId, customerId, fullPath, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/videos/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete customer photo.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} fullPath - Escaped full path to photo
     */
  deleteCustomerPhotoAction(companyId, customerId, fullPath, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/photos/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Export customers to CSV file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.year - Year
       * @param {} parameters.q - 
       * @param {} parameters.owner - 
       * @param {} parameters.sorter - Define sort order
       * @param {} parameters.active - 
       * @param {} parameters.allCustomers - 
     */
  getCustomersCsvAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers-export-csv';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['owner'] !== 'undefined') {
      queryParameters['owner'] = parameters['owner'];
    }

    queryParameters['owner'] = this.transformParameter(
      queryParameters['owner'],
    );

    queryParameters['sorter'] = 'name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    if (typeof parameters['allCustomers'] !== 'undefined') {
      queryParameters['allCustomers'] = parameters['allCustomers'];
    }

    queryParameters['allCustomers'] = this.transformParameter(
      queryParameters['allCustomers'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Move customers from one user to another user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} userId - ID of user
       * @param {} parameters.force - 
       * @param {} parameters.body - 
     */
  putCustomersOwnershipsAction(companyId, userId, parameters = {}) {
    let path = '/customers/{companyId}/ownership/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    if (typeof parameters['force'] !== 'undefined') {
      queryParameters['force'] = parameters['force'];
    }

    queryParameters['force'] = this.transformParameter(
      queryParameters['force'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Batch state update of customers.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - The status data
     */
  putCustomersStatusAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers/status';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Discounts approval decision.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.approvalOperation - Operation
     */
  putCustomerB2CDiscountsApprovalDecision(
    companyId,
    customerId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/customers/{customerId}/discounts-approval';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['approvalOperation'] !== 'undefined') {
      queryParameters['approvalOperation'] = parameters['approvalOperation'];
    }

    queryParameters['approvalOperation'] = this.transformParameter(
      queryParameters['approvalOperation'],
    );

    if (typeof parameters['approvalOperation'] === 'undefined') {
      throw Error('Missing required parameter: approvalOperation');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update customer software

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - The status data
     */
  putCustomerWarehouseSoftwareAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/warehouse-software';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update customer branches

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - The status data
     */
  putCustomerBranchesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/branches-company';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update customer description

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - The status data
     */
  putCustomerBasicInfoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/basic-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update customer  segment category

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - The status data
     */
  putCustomerSegmentCategoryAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/segment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of customers shops

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.customerId - 
       * @param {} parameters.sorter - 
     */
  getCustomersShopsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers/shops';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create customer shop

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postCustomerShopAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers/shop';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of customer shop

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} shopId - Shop id
     */
  getCustomerShopAction(companyId, shopId, parameters = {}) {
    let path = '/crm/{companyId}/customers/shops/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update customer shop

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} shopId - Shop id
       * @param {} parameters.body - 
     */
  putCustomerShopAction(companyId, shopId, parameters = {}) {
    let path = '/crm/{companyId}/customers/shops/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove customer shop

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} shopId - Shop id
     */
  deleteCustomerShopAction(companyId, shopId, parameters = {}) {
    let path = '/crm/{companyId}/customers/shops/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of customer shop

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} latitude - gps position - latitude
       * @param {} longitude - gps position - longitude
     */
  getNearestCustomerShopAction(
    companyId,
    latitude,
    longitude,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/customers/shop-nearest/{latitude}/{longitude}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{latitude}', latitude);

    path = path.replace('{longitude}', longitude);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload image to customer shop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} shopId - Shop id
       * @param {} parameters.body - 
     */
  postCustomerShopImageAction(companyId, shopId, parameters = {}) {
    let path = '/crm/{companyId}/customers/shops/{shopId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete image customer shop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} shopId - Shop id
       * @param {} fullPath - Escaped full path to image
     */
  deleteCustomerShopImageAction(companyId, shopId, fullPath, parameters = {}) {
    let path = '/crm/{companyId}/customers/shops/{shopId}/image/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of contacts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.active - 
       * @param {} parameters.subscriberOnly - 
       * @param {} parameters.onlyOwned - 
       * @param {} parameters.deleted - 
       * @param {} parameters.owner - 
       * @param {} parameters.sorter - 
       * @param {} parameters.q - 
     */
  getContactsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    if (typeof parameters['subscriberOnly'] !== 'undefined') {
      queryParameters['subscriberOnly'] = parameters['subscriberOnly'];
    }

    queryParameters['subscriberOnly'] = this.transformParameter(
      queryParameters['subscriberOnly'],
    );

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    if (typeof parameters['deleted'] !== 'undefined') {
      queryParameters['deleted'] = parameters['deleted'];
    }

    queryParameters['deleted'] = this.transformParameter(
      queryParameters['deleted'],
    );

    if (typeof parameters['owner'] !== 'undefined') {
      queryParameters['owner'] = parameters['owner'];
    }

    queryParameters['owner'] = this.transformParameter(
      queryParameters['owner'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of contacts.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getB2BContactsAction(parameters = {}) {
    let path = '/b2b/contacts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Customer details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.year - Year
     */
  getB2BCustomerAction(parameters = {}) {
    let path = '/b2b/customer';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of contact.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
     */
  getContactAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update details of contact.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} parameters.body - 
     */
  putContactAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete contact.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
     */
  deleteContactAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get test emails list.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getTestEmailsList(parameters = {}) {
    let path = '/contacts/test-emails';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Set contact to default.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
     */
  putContactDefaultAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/default';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update EAN code of contact.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} parameters.body - 
     */
  putContactEanAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/ean/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of opportunities linked to contact.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} contactId - ID ofcontact
   */
  getContactOpportunitiesAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of activities from MS graph linked to contact by customer ID.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} parameters.from - 
       * @param {} parameters.to - 
     */
  getContactCalendarAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/calendar';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (typeof parameters['from'] !== 'undefined') {
      queryParameters['from'] = parameters['from'];
    }

    queryParameters['from'] = this.transformParameter(queryParameters['from']);

    if (typeof parameters['to'] !== 'undefined') {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters['to'] = this.transformParameter(queryParameters['to']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of newsletters sent to contact.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.sorter - 
     */
  getContactNewsletters(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/newsletters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get email logs for contact.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} contactId - ID ofcontact
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.sorter -
   */
  getContactEmailLogsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/email-logs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update contact credentials.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} parameters.copyFromB2B - 
       * @param {} parameters.body - 
     */
  putContactXPaintCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials-xpaint';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (typeof parameters['copyFromB2B'] !== 'undefined') {
      queryParameters['copyFromB2B'] = parameters['copyFromB2B'];
    }

    queryParameters['copyFromB2B'] = this.transformParameter(
      queryParameters['copyFromB2B'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update contact credentials.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
     */
  deleteContactXPaintCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials-xpaint';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get contact credentials.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
     */
  getContactCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update contact credentials.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} parameters.body - 
     */
  putContactCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update contact credentials.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
     */
  deleteContactCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Return contact customers.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
     */
  getContactsCustomersAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contact/{contactId}/customers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add new customer to contact.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} customerId - Customer ID
     */
  postAddContactCustomerAction(
    companyId,
    contactId,
    customerId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/contact/{contactId}/customer/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update contact customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} customerId - Customer ID
     */
  putContactCustomerAction(companyId, contactId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/contact/{contactId}/customer/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove customer from contact.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} contactId - ID ofcontact
       * @param {} customerId - Customer ID
     */
  deleteContactCustomerAction(
    companyId,
    contactId,
    customerId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/contact/{contactId}/customer/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of competitors.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.sorter - 
       * @param {} parameters.q - 
     */
  getCompetitorsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/competitors';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create competitor
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.body -
   */
  postCompetitorAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/competitors';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of competitor.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} competitorId - ID of competitor
     */
  getCompetitorAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/{competitorId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update detail of competitor.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} competitorId - ID of competitor
       * @param {} parameters.body - 
     */
  putCompetitorAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/{competitorId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete competitor
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} competitorId - ID of competitor
   */
  deleteCompetitorAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/{competitorId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get business info of competitor.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} competitorId - ID of competitor
     */
  getCompetitorBusinessInfoAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/businessConditions/{competitorId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return orders from NAV.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.lastKey - Key off last result
   * @param {} parameters.firsKey - Key off firs result
   * @param {} parameters.customerNavId - Filter by customer nav id
   * @param {} parameters.sorter -
   * @param {} parameters.year - Year
   * @param {} parameters.q -
   * @param {} parameters.month -
   * @param {} parameters.day -
   * @param {} parameters.status -
   */
  getOrdersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/orders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['lastKey'] !== 'undefined') {
      queryParameters['lastKey'] = parameters['lastKey'];
    }

    queryParameters['lastKey'] = this.transformParameter(
      queryParameters['lastKey'],
    );

    if (typeof parameters['firsKey'] !== 'undefined') {
      queryParameters['firsKey'] = parameters['firsKey'];
    }

    queryParameters['firsKey'] = this.transformParameter(
      queryParameters['firsKey'],
    );

    if (typeof parameters['customerNavId'] !== 'undefined') {
      queryParameters['customerNavId'] = parameters['customerNavId'];
    }

    queryParameters['customerNavId'] = this.transformParameter(
      queryParameters['customerNavId'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    if (typeof parameters['day'] !== 'undefined') {
      queryParameters['day'] = parameters['day'];
    }

    queryParameters['day'] = this.transformParameter(queryParameters['day']);

    queryParameters['status'] = 'historic';

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of order templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.onlyOwned -
   */
  getOrderTemplatesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/order-template';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Transfer order from NAV to EOM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} invoiceId - Id of invoice
   */
  transferNavOrderToEOMAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/nav-order-eom/{invoiceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return orders from NAV.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  getOrderAction(companyId, orderId, parameters = {}) {
    let path = '/crm/{companyId}/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update order by NAV.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  getUpdateOrderByNav(companyId, orderId, parameters = {}) {
    let path = '/crm/{companyId}/orders/{orderId}/update-by-navision';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * CRM delivery types by customer firm.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
     */
  getDeliveryTypesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/delivery-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * CRM payment types by firm.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
     */
  getPaymentTypesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/payment-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get CRM Order invoice file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} orderId - ID of order
     */
  getOrderInvoiceAction(companyId, orderId, parameters = {}) {
    let path = '/crm/{companyId}/orders/{orderId}/invoice';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List user settings.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getUserSettingsAction(parameters = {}) {
    let path = '/me/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Set user setting.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postUserSettingAction(parameters = {}) {
    let path = '/me/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List user recent actions.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getUserRecentsAction(parameters = {}) {
    let path = '/me/recent';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create user recent action.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postUserRecentAction(parameters = {}) {
    let path = '/me/recent';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get users.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.companyId - Shortcut of location
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter - Define sort order
   */
  getAllUsersAction(parameters = {}) {
    let path = '/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['companyId'] !== 'undefined') {
      queryParameters['companyId'] = parameters['companyId'];
    }

    queryParameters['companyId'] = this.transformParameter(
      queryParameters['companyId'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = 'display_name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get user info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} userId - ID of user
   */
  getUserAction(userId, parameters = {}) {
    let path = '/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get user info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} userId - ID of user
   * @param {} parameters.body -
   */
  putUserAction(userId, parameters = {}) {
    let path = '/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get user info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} userId - ID of user
   * @param {} parameters.body -
   */
  putUserSettingsAction(userId, parameters = {}) {
    let path = '/users/{userId}/crm-settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload user photo.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} userId - ID of user
       * @param {} parameters.body - 
     */
  putUserProfilePictureAction(userId, parameters = {}) {
    let path = '/users/{userId}/profile-picture';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Save push notification token.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} userId - ID of user
       * @param {} parameters.body - 
     */
  putUserNotificationTokenAction(userId, parameters = {}) {
    let path = '/users/{userId}/notification-token';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete push notification token.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} userId - ID of user
       * @param {} parameters.deviceInfo - 
       * @param {} parameters.body - 
     */
  deleteUserNotificationTokenAction(userId, parameters = {}) {
    let path = '/users/{userId}/remove-notification-token';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId);

    if (typeof parameters['deviceInfo'] !== 'undefined') {
      queryParameters['deviceInfo'] = parameters['deviceInfo'];
    }

    queryParameters['deviceInfo'] = this.transformParameter(
      queryParameters['deviceInfo'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Set contact to default.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
     */
  getUsersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update user settings.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putUserAppLangAction(parameters = {}) {
    let path = '/crm/user/activeAppLang';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get business info based on ICO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  getNavisionProducts(companyId, parameters = {}) {
    let path = '/{companyId}/products-sync';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update product for eshop in catalog_products_filters_search collection.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  updateFilterSearchCollectionAction(companyId, parameters = {}) {
    let path = '/{companyId}/products-export-filter-search';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Synchronization of firm customers
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  syncNavisionCustomers(companyId, parameters = {}) {
    let path = '/{companyId}/customers-sync';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * et user info by email.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.email - 
     */
  getUserByEmailAction(parameters = {}) {
    let path = '/email-verification';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw Error('Missing required parameter: email');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Synchronizacion of turnover
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  navisionSyncCustomersTurnoverAndBudgetEntryAction(
    companyId,
    parameters = {},
  ) {
    let path = '/{companyId}/navision-sync-customers-turnover-and-budget-entry';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Synchronizacion of turnover - full year
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  navisionSyncFullYearCustomersTurnoverAndBudgetEntryAction(
    companyId,
    parameters = {},
  ) {
    let path =
      '/{companyId}/navision-full-year-sync-customers-turnover-and-budget-entry';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get translations for app.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.app - 
     */
  getTranslationsAction(parameters = {}) {
    let path = '/translations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['app'] !== 'undefined') {
      queryParameters['app'] = parameters['app'];
    }

    queryParameters['app'] = this.transformParameter(queryParameters['app']);

    if (typeof parameters['app'] === 'undefined') {
      throw Error('Missing required parameter: app');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get translations for app.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.app - 
     */
  getB2BTranslationsAction(parameters = {}) {
    let path = '/b2b/translations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['app'] !== 'undefined') {
      queryParameters['app'] = parameters['app'];
    }

    queryParameters['app'] = this.transformParameter(queryParameters['app']);

    if (typeof parameters['app'] === 'undefined') {
      throw Error('Missing required parameter: app');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get langs for app.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.app - 
     */
  getB2BLanguagesAction(parameters = {}) {
    let path = '/b2b/langs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['app'] !== 'undefined') {
      queryParameters['app'] = parameters['app'];
    }

    queryParameters['app'] = this.transformParameter(queryParameters['app']);

    if (typeof parameters['app'] === 'undefined') {
      throw Error('Missing required parameter: app');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get roles
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BRolesAction(parameters = {}) {
    let path = '/b2b/roles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get translations for CRM app.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} lng - Lang for i18n
       * @param {} ns - Namespace for i18n
     */
  getCrmI18nTranslationsAction(lng, ns, parameters = {}) {
    let path = '/crm/translations/{lng}/{ns}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{lng}', lng);

    path = path.replace('{ns}', ns);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get langs for app.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.app - 
     */
  getLanguagesAction(parameters = {}) {
    let path = '/langs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['app'] !== 'undefined') {
      queryParameters['app'] = parameters['app'];
    }

    queryParameters['app'] = this.transformParameter(queryParameters['app']);

    if (typeof parameters['app'] === 'undefined') {
      throw Error('Missing required parameter: app');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of calculation tables

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.customerId - 
       * @param {} parameters.opportunityId - 
       * @param {} parameters.status - 
       * @param {} parameters.sorter - Define sort order
     */
  getPriceOffersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['opportunityId'] !== 'undefined') {
      queryParameters['opportunityId'] = parameters['opportunityId'];
    }

    queryParameters['opportunityId'] = this.transformParameter(
      queryParameters['opportunityId'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    queryParameters['sorter'] = 'name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new price offer

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postPriceOfferAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Detail of price offer

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
     */
  getPriceOfferAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update price offer

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
       * @param {} parameters.body - 
     */
  putPriceOfferAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete price offer

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
     */
  deletePriceOfferAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create order from price offer

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
       * @param {} parameters.body - 
     */
  postPriceOfferOrderAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}/order';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new price offer

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postEmptyPriceOfferAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/empty-price-offer';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new calculation table

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
     */
  postPriceOfferCopyAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/copy-price-offers/{priceOfferId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Price offer pdf file to download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
     */
  getPriceOfferDownloadAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update campaign details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
       * @param {} parameters.body - 
     */
  putSendForApprovalOfferRequestDecisionAction(
    companyId,
    priceOfferId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}/send-for-approval';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update campaign details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
       * @param {} parameters.body - 
     */
  putPriceOfferRequestDecisionAction(companyId, priceOfferId, parameters = {}) {
    let path =
      '/crm/{companyId}/price-offers/{priceOfferId}/approval-request-decision';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update campaign details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} priceOfferId - ID of price offer
       * @param {} parameters.body - 
     */
  sendPriceOfferAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}/send-offer';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of calculation tables

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.status - 
       * @param {} parameters.offerIds - 
       * @param {} parameters.sorter - Define sort order
     */
  getB2BPriceOffersAction(parameters = {}) {
    let path = '/b2b/price-offers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['offerIds'] !== 'undefined') {
      queryParameters['offerIds'] = parameters['offerIds'];
    }

    queryParameters['offerIds'] = this.transformParameter(
      queryParameters['offerIds'],
    );

    queryParameters['sorter'] = 'name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add price offer items to cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} parameters.addToExistingItem - 
       * @param {} parameters.body - 
     */
  postPriceOffersProductsCartItemsAction(cartId, parameters = {}) {
    let path = '/b2b/cart-priceOffer/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['addToExistingItem'] !== 'undefined') {
      queryParameters['addToExistingItem'] = parameters['addToExistingItem'];
    }

    queryParameters['addToExistingItem'] = this.transformParameter(
      queryParameters['addToExistingItem'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Return list of customers price offers products for B2B.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.offerIds - 
     */
  getB2BPriceOffersProductsAction(parameters = {}) {
    let path = '/b2b/price-offers-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['offerIds'] !== 'undefined') {
      queryParameters['offerIds'] = parameters['offerIds'];
    }

    queryParameters['offerIds'] = this.transformParameter(
      queryParameters['offerIds'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of price offer templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.onlyOwned -
   */
  getPriceOfferTemplatesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers-template';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Pricelists.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - 
     */
  getPricelistsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload pricelist.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postPricelistAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Pricelists details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} pricelistId - Pricelist file object

     */
  getPricelistAction(companyId, pricelistId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists/{pricelistId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{pricelistId}', pricelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete Pricelist.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} pricelistId - Pricelist file object

     */
  deletePricelistAction(companyId, pricelistId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists/{pricelistId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{pricelistId}', pricelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of customers invoices.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.year - 
       * @param {} parameters.sorter - 
       * @param {} parameters.month - Month
       * @param {} parameters.customerId - Filter by customer
     */
  getInvoicesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/invoices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters['sorter'] = '-due_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Detail of invoice.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} invoiceId - Id of invoice
     */
  getInvoiceAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/invoices/{invoiceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Rows of invoice.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} invoiceId - Id of invoice
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - 
     */
  getInvoiceRowsAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/invoices/{invoiceId}/rows';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Download invocie PDF.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} invoiceId - Id of invoice
     */
  getInvoicePDFFileAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/invoices/{invoiceId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of customers invoices.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.year - 
       * @param {} parameters.sorter - 
     */
  getB2BInvoicesAction(parameters = {}) {
    let path = '/b2b/invoices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters['sorter'] = '-due_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Detail of invoice.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} invoiceId - Id of invoice
     */
  getB2BInvoiceAction(invoiceId, parameters = {}) {
    let path = '/b2b/invoices/{invoiceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Rows of invoice.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} invoiceId - Id of invoice
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - 
     */
  getB2BInvoiceRowsAction(invoiceId, parameters = {}) {
    let path = '/b2b/invoices/{invoiceId}/rows';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Download invocie PDF.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} invoiceId - Id of invoice
     */
  getB2BInvoicePDFFileAction(invoiceId, parameters = {}) {
    let path = '/b2b/invoices/{invoiceId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of logged user services.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.status - 
       * @param {} parameters.sorter - 
       * @param {} parameters.onlyOwned - 
     */
  getUserHelpdeskServicesAction(parameters = {}) {
    let path = '/me/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters['onlyOwned'] = true;

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of services.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.status - 
       * @param {} parameters.sorter - 
       * @param {} parameters.onlyOwned - 
       * @param {} parameters.q - 
     */
  getHelpdeskServicesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create helpdesk service.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postHelpdeskServiceAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of service solvers.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.type - Type of service
       * @param {} parameters.subtype - Subtype of service
     */
  getHelpdeskServiceSolversAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk-solvers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['subtype'] !== 'undefined') {
      queryParameters['subtype'] = parameters['subtype'];
    }

    queryParameters['subtype'] = this.transformParameter(
      queryParameters['subtype'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Helpdesk service details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
     */
  getHelpdeskServiceAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update helpdesk service.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
       * @param {} parameters.body - 
     */
  putHelpdeskServiceAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete helpdesk service.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
     */
  deleteHelpdeskServiceAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of attachments linked to helpdesk service.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
     */
  getHelpdeskServiceAttachmentsAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/attachments';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of attachments linked to helpdesk service.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
       * @param {} parameters.historyId - 
       * @param {} parameters.descriptionFile - 
       * @param {} parameters.body - 
     */
  postHelpdeskServiceAttachmentAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/attachments';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    if (typeof parameters['historyId'] !== 'undefined') {
      queryParameters['historyId'] = parameters['historyId'];
    }

    queryParameters['historyId'] = this.transformParameter(
      queryParameters['historyId'],
    );

    if (typeof parameters['descriptionFile'] !== 'undefined') {
      queryParameters['descriptionFile'] = parameters['descriptionFile'];
    }

    queryParameters['descriptionFile'] = this.transformParameter(
      queryParameters['descriptionFile'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of attachments linked to helpdesk service.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
       * @param {} fullPath - Escaped full path to attachment
     */
  deleteHelpdeskServiceAttachmentAction(
    companyId,
    serviceId,
    fullPath,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/attachments/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of service activities.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
       * @param {} parameters.sorter - 
     */
  getHelpdeskServiceActivitiesAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of service history.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
     */
  getHelpdeskServiceHistory(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/history';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add new item in helpdesk service history.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} serviceId - ID of helpdesk service
       * @param {} parameters.sendNotification - 
       * @param {} parameters.body - 
     */
  putHelpdeskHistoryAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/history';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters['sendNotification'] = true;

    if (typeof parameters['sendNotification'] !== 'undefined') {
      queryParameters['sendNotification'] = parameters['sendNotification'];
    }

    queryParameters['sendNotification'] = this.transformParameter(
      queryParameters['sendNotification'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get business info of customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} codelistId - ID od codelist
     */
  getCodelistAction(codelistId, parameters = {}) {
    let path = '/crm/codelist/{codelistId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{codelistId}', codelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get business info of customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} codelistId - ID od codelist
     */
  getB2BCodeListAction(codelistId, parameters = {}) {
    let path = '/b2b/codelist/{codelistId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{codelistId}', codelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Machines list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - 
       * @param {} parameters.active - 
       * @param {} parameters.onlyOwned - 
       * @param {} parameters.customerId - 
     */
  getMachinesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/machines';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new machine.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postMachineAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/machines';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Machine detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} machineId - ID od machine

     */
  getMachineAction(companyId, machineId, parameters = {}) {
    let path = '/crm/{companyId}/machines/{machineId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{machineId}', machineId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update machine.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} machineId - ID od machine

       * @param {} parameters.body - 
     */
  putMachineAction(companyId, machineId, parameters = {}) {
    let path = '/crm/{companyId}/machines/{machineId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{machineId}', machineId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete machine.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} machineId - ID od machine

     */
  deleteMachineAction(companyId, machineId, parameters = {}) {
    let path = '/crm/{companyId}/machines/{machineId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{machineId}', machineId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get customers machines.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   */
  getCustomerMachinesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/machines';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Activate/deactivate multiple machines.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  putMachinesStatusAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/machines/status';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of toning system

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} systemId - Toning System ID
     */
  getToningSystemAction(companyId, systemId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems/{systemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{systemId}', systemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update details of toning system

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} systemId - Toning System ID
       * @param {} parameters.forced - 
       * @param {} parameters.body - 
     */
  putToningSystemAction(companyId, systemId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems/{systemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{systemId}', systemId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete toning system

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} systemId - Toning System ID
       * @param {} parameters.forced - 
     */
  deleteToningSystemAction(companyId, systemId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems/{systemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{systemId}', systemId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} productId - ID of product
   * @param {} parameters.everyPrices -
   */
  getProductAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['everyPrices'] !== 'undefined') {
      queryParameters['everyPrices'] = parameters['everyPrices'];
    }

    queryParameters['everyPrices'] = this.transformParameter(
      queryParameters['everyPrices'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.q -
   */
  getBrandsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products/brands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new brand.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postBrandAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products/brands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of sub product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.q -
   */
  getSubBrandsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products/sub-brands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new sub brand.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postSubBrandAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products/sub-brands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create brand.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} brandId - ID of brand
       * @param {} parameters.body - 
     */
  putBrandAction(companyId, brandId, parameters = {}) {
    let path = '/crm/{companyId}/products/brand/{brandId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brandId}', brandId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete brand.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} brandId - ID of brand
     */
  deleteBrandAction(companyId, brandId, parameters = {}) {
    let path = '/crm/{companyId}/products/brand/{brandId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brandId}', brandId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete sub brand.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} brandId - ID of brand
     */
  deleteSubBrandAction(companyId, brandId, parameters = {}) {
    let path = '/crm/{companyId}/products/sub-brand/{brandId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brandId}', brandId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.q -
   */
  getB2BBrandsAction(parameters = {}) {
    let path = '/b2b/products/brands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return products with prices and VAT.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   * @param {} parameters.customerId -
   * @param {} parameters.productIds -
   */
  getProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['productIds'] !== 'undefined') {
      queryParameters['productIds'] = parameters['productIds'];
    }

    queryParameters['productIds'] = this.transformParameter(
      queryParameters['productIds'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} productId - ID of product
   */
  getProductInventoryAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/products/{productId}/inventory';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Response is generated description by AI.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.lang - Lang of generated content
   * @param {} parameters.body -
   */
  generateAIContentAction(parameters = {}) {
    let path = '/products/ai-description-gen';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['lang'] === 'undefined') {
      throw Error('Missing required parameter: lang');
    }

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Post B2B Order.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postB2BOrderAction(parameters = {}) {
    let path = '/b2b/orders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return orders from NAV.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.sorter -
   * @param {} parameters.year - Year
   * @param {} parameters.q -
   * @param {} parameters.month -
   */
  getB2bOrdersAction(parameters = {}) {
    let path = '/b2b/orders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['month'] !== 'undefined') {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters['month'] = this.transformParameter(
      queryParameters['month'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get B2B Order detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderId - ID of order
     */
  getB2BOrderAction(orderId, parameters = {}) {
    let path = '/b2b/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get B2B Order invoice file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderId - ID of order
     */
  getB2BOrderInvoiceAction(orderId, parameters = {}) {
    let path = '/b2b/orders/{orderId}/invoice';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get news detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news
     */
  getNewsAction(newsId, parameters = {}) {
    let path = '/b2b/news/{newsId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get published news.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.lang - 
     */
  getNewsListAction(parameters = {}) {
    let path = '/b2b/news';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get cart info.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} parameters.cached - Return cart overview
     */
  getCartAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['cached'] !== 'undefined') {
      queryParameters['cached'] = parameters['cached'];
    }

    queryParameters['cached'] = this.transformParameter(
      queryParameters['cached'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add item to cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} parameters.addToExistingItem - 
       * @param {} parameters.body - 
     */
  postCartItemAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['addToExistingItem'] !== 'undefined') {
      queryParameters['addToExistingItem'] = parameters['addToExistingItem'];
    }

    queryParameters['addToExistingItem'] = this.transformParameter(
      queryParameters['addToExistingItem'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cart metadata.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} parameters.body - 
     */
  putCartAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
     */
  deleteCartAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Eshop order import.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.cartId - 
       * @param {} parameters.body - 
     */
  postOrderImport(parameters = {}) {
    let path = '/eshop/cart/import';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (typeof parameters['cartId'] !== 'undefined') {
      queryParameters['cartId'] = parameters['cartId'];
    }

    queryParameters['cartId'] = this.transformParameter(
      queryParameters['cartId'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  createCartAction(parameters = {}) {
    let path = '/eshop/cart';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get carts.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCartsAction(parameters = {}) {
    let path = '/eshop/cart';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} productId - ID of product
       * @param {} parameters.colorCode - 
       * @param {} parameters.isWh010Sale - 
       * @param {} parameters.body - 
     */
  putCartProductAction(cartId, productId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['colorCode'] !== 'undefined') {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    queryParameters['colorCode'] = this.transformParameter(
      queryParameters['colorCode'],
    );

    if (typeof parameters['isWh010Sale'] !== 'undefined') {
      queryParameters['isWh010Sale'] = parameters['isWh010Sale'];
    }

    queryParameters['isWh010Sale'] = this.transformParameter(
      queryParameters['isWh010Sale'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} productId - ID of product
       * @param {} parameters.campaignProduct - 
       * @param {} parameters.colorCode - 
       * @param {} parameters.offerId - 
       * @param {} parameters.isWh010Sale - 
     */
  deleteCartProductAction(cartId, productId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['campaignProduct'] !== 'undefined') {
      queryParameters['campaignProduct'] = parameters['campaignProduct'];
    }

    queryParameters['campaignProduct'] = this.transformParameter(
      queryParameters['campaignProduct'],
    );

    if (typeof parameters['colorCode'] !== 'undefined') {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    queryParameters['colorCode'] = this.transformParameter(
      queryParameters['colorCode'],
    );

    if (typeof parameters['offerId'] !== 'undefined') {
      queryParameters['offerId'] = parameters['offerId'];
    }

    queryParameters['offerId'] = this.transformParameter(
      queryParameters['offerId'],
    );

    if (typeof parameters['isWh010Sale'] !== 'undefined') {
      queryParameters['isWh010Sale'] = parameters['isWh010Sale'];
    }

    queryParameters['isWh010Sale'] = this.transformParameter(
      queryParameters['isWh010Sale'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} productId - ID of product
       * @param {} parameters.colorCode - 
       * @param {} parameters.offerId - 
       * @param {} parameters.isWh010Sale - 
       * @param {} parameters.body - 
     */
  putCartProductDescAction(cartId, productId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/{productId}/description';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['colorCode'] !== 'undefined') {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    queryParameters['colorCode'] = this.transformParameter(
      queryParameters['colorCode'],
    );

    if (typeof parameters['offerId'] !== 'undefined') {
      queryParameters['offerId'] = parameters['offerId'];
    }

    queryParameters['offerId'] = this.transformParameter(
      queryParameters['offerId'],
    );

    if (typeof parameters['isWh010Sale'] !== 'undefined') {
      queryParameters['isWh010Sale'] = parameters['isWh010Sale'];
    }

    queryParameters['isWh010Sale'] = this.transformParameter(
      queryParameters['isWh010Sale'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} productId - ID of product
   * @param {} parameters.everyPrices -
   */
  getB2BProductAction(productId, parameters = {}) {
    let path = '/b2b/products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['everyPrices'] !== 'undefined') {
      queryParameters['everyPrices'] = parameters['everyPrices'];
    }

    queryParameters['everyPrices'] = this.transformParameter(
      queryParameters['everyPrices'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add voucher to cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} parameters.addToExistingItem - 
       * @param {} parameters.body - 
     */
  postCartVoucherAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/apply-voucher';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['addToExistingItem'] !== 'undefined') {
      queryParameters['addToExistingItem'] = parameters['addToExistingItem'];
    }

    queryParameters['addToExistingItem'] = this.transformParameter(
      queryParameters['addToExistingItem'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart voucher.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} parameters.body - 
     */
  deleteCartVoucherAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/apply-voucher';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} configrationId - ID of configuration
       * @param {} parameters.colorCode - 
     */
  deleteCartConfigurationProductAction(
    cartId,
    configrationId,
    parameters = {},
  ) {
    let path = '/eshop/cart/{cartId}/configuration/{configurationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    path = path.replace('{configrationId}', configrationId);

    if (typeof parameters['colorCode'] !== 'undefined') {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    queryParameters['colorCode'] = this.transformParameter(
      queryParameters['colorCode'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add item to cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
       * @param {} parameters.body - 
     */
  postCartConfigurationItemsAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/configuration';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of B2B customers shops.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BCustomerShopsAction(parameters = {}) {
    let path = '/b2b/shops';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create B2B customer shop

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postB2BCustomerShopAction(parameters = {}) {
    let path = '/b2b/shop';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of B2B customer shop

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shopId - Shop id
     */
  getB2BCustomerShopAction(shopId, parameters = {}) {
    let path = '/b2b/shops/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update B2B customer shop

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shopId - Shop id
       * @param {} parameters.body - 
     */
  putB2BCustomerShopAction(shopId, parameters = {}) {
    let path = '/b2b/shops/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{shopId}', shopId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get user detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} contactId - ID ofcontact
   */
  getB2BUserAction(contactId, parameters = {}) {
    let path = '/b2b/users/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return products with prices and VAT.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {array} parameters.campaignIds -
   * @param {} parameters.onlyCampaignProducts -
   * @param {} parameters.sorter -
   * @param {} parameters.brands -
   */
  getB2bProductsAction(parameters = {}) {
    let path = '/b2b/products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['campaignIds'] !== 'undefined') {
      queryParameters['campaignIds'] = parameters['campaignIds'];
    }

    queryParameters['campaignIds'] = this.transformParameter(
      queryParameters['campaignIds'],
    );

    if (typeof parameters['onlyCampaignProducts'] !== 'undefined') {
      queryParameters['onlyCampaignProducts'] =
        parameters['onlyCampaignProducts'];
    }

    queryParameters['onlyCampaignProducts'] = this.transformParameter(
      queryParameters['onlyCampaignProducts'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['brands'] !== 'undefined') {
      queryParameters['brands'] = parameters['brands'];
    }

    queryParameters['brands'] = this.transformParameter(
      queryParameters['brands'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return product details by EAN.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.ean -
   */
  getB2bProductByEanAction(parameters = {}) {
    let path = '/b2b/product-by-ean';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['ean'] !== 'undefined') {
      queryParameters['ean'] = parameters['ean'];
    }

    queryParameters['ean'] = this.transformParameter(queryParameters['ean']);

    if (typeof parameters['ean'] === 'undefined') {
      throw Error('Missing required parameter: ean');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return catalog products for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   * @param {} parameters.canBeToned -
   * @param {} parameters.categoryName - Category of publication name
   */
  getB2BCatalogProductsAction(parameters = {}) {
    let path = '/b2b/catalog-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['canBeToned'] !== 'undefined') {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    queryParameters['canBeToned'] = this.transformParameter(
      queryParameters['canBeToned'],
    );

    if (typeof parameters['categoryName'] !== 'undefined') {
      queryParameters['categoryName'] = parameters['categoryName'];
    }

    queryParameters['categoryName'] = this.transformParameter(
      queryParameters['categoryName'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} productId - ID of product
   */
  getB2BCatalogProductAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of campaigns for B2B.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.state - 
       * @param {} parameters.sorter - 
     */
  getB2BCustomerCampaignsAction(parameters = {}) {
    let path = '/b2b/campaigns';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['state'] = 'active';

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return dejmark products with variants exported to json file.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getProductsFileAction(parameters = {}) {
    let path = '/products-export-to-file';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return dejmark products with variants for company Farlesk.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit for paginated results
   * @param {} parameters.offset - Offset parameter
   */
  getProductsExportFarleskAction(parameters = {}) {
    let path = '/farlesk-products-export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return dejmark products with variants for company Kopelent.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit for paginated results
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.brand - Brand of product
   * @param {} parameters.url - url of product
   * @param {} parameters.revalidateCache - url of product
   */
  getProductsExportKopelentAction(parameters = {}) {
    let path = '/kopelent-products-export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['revalidateCache'] !== 'undefined') {
      queryParameters['revalidateCache'] = parameters['revalidateCache'];
    }

    queryParameters['revalidateCache'] = this.transformParameter(
      queryParameters['revalidateCache'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return dejmark products with variants for company Seko.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit for paginated results
   * @param {} parameters.offset - Offset parameter
   */
  getSEKOProductsExportAction(parameters = {}) {
    let path = '/seko-products-export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return dejmark products with variants for company Obnova.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit for paginated results
   * @param {} parameters.offset - Offset parameter
   */
  getObnovaProductsExportAction(parameters = {}) {
    let path = '/obnova-products-export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return dejmark products with variants for company Homola exported to json file.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getProductsExportHomolaAction(parameters = {}) {
    let path = '/homola-products-export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of cash registers.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.onlyProduction -
   * @param {} parameters.companyId -
   */
  getCashRegistersListAction(parameters = {}) {
    let path = '/cash-register/list';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['onlyProduction'] = true;

    if (typeof parameters['onlyProduction'] !== 'undefined') {
      queryParameters['onlyProduction'] = parameters['onlyProduction'];
    }

    queryParameters['onlyProduction'] = this.transformParameter(
      queryParameters['onlyProduction'],
    );

    if (typeof parameters['companyId'] !== 'undefined') {
      queryParameters['companyId'] = parameters['companyId'];
    }

    queryParameters['companyId'] = this.transformParameter(
      queryParameters['companyId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get bills list
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.shopId -
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   * @param {} parameters.payments -
   * @param {} parameters.contactId -
   * @param {} parameters.status -
   * @param {} parameters.sorter - Define sort order
   */
  getKasaBillsAction(companyId, parameters = {}) {
    let path = '/cash-register/{companyId}/bills/list';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shop_id'] = parameters['shopId'];
    }

    queryParameters['shop_id'] = this.transformParameter(
      queryParameters['shop_id'],
    );

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    if (typeof parameters['payments'] !== 'undefined') {
      queryParameters['payments'] = parameters['payments'];
    }

    queryParameters['payments'] = this.transformParameter(
      queryParameters['payments'],
    );

    if (typeof parameters['contactId'] !== 'undefined') {
      queryParameters['contact_id'] = parameters['contactId'];
    }

    queryParameters['contact_id'] = this.transformParameter(
      queryParameters['contact_id'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    queryParameters['sorter'] = 'createdDate';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get cash register daily report list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.shopId -
   * @param {} parameters.sorter - DailyReport sort
   */
  getKasaDailyReportsAction(companyId, parameters = {}) {
    let path = '/cash-register/{companyId}/daily-report';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shopId'] = parameters['shopId'];
    }

    queryParameters['shopId'] = this.transformParameter(
      queryParameters['shopId'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get cash register daily report list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} dailyReportId -
   */
  getKasaDailyReportDetailAction(companyId, dailyReportId, parameters = {}) {
    let path = '/cash-register/{companyId}/daily-report/{dailyReportId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{dailyReportId}', dailyReportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get cash register deposit and withdrawals list
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.shopId -
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   * @param {} parameters.action -
   * @param {} parameters.sorter - Define sort items
   */
  getKasaDepositWithdrawalsListAction(companyId, parameters = {}) {
    let path = '/cash-register/{companyId}/deposit-withdrawals';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shopId'] = parameters['shopId'];
    }

    queryParameters['shopId'] = this.transformParameter(
      queryParameters['shopId'],
    );

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    if (typeof parameters['action'] !== 'undefined') {
      queryParameters['action'] = parameters['action'];
    }

    queryParameters['action'] = this.transformParameter(
      queryParameters['action'],
    );

    queryParameters['sorter'] = 'created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get cash register deposit withdrawal
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} reportId -
   */
  getKasaDepositWithdrawalsDetailAction(companyId, reportId, parameters = {}) {
    let path = '/cash-register/{companyId}/deposit-withdrawals/{reportId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get bill detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} billId -
   */
  getKasaBillAction(billId, parameters = {}) {
    let path = '/cash-register/bill/{billId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{billId}', billId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create kasa bill from fin portal data
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  createKasaBillFinPortalAction(parameters = {}) {
    let path = '/cash-register/bill/fin-portal';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Re-send bill to navision
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} billId -
   */
  sendKasaBillToNavAction(companyId, billId, parameters = {}) {
    let path = '/cash-register/{companyId}/bill/{billId}/nav-send';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{billId}', billId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create cash register money deposit
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  postDepositWithdrawAction(parameters = {}) {
    let path = '/cash-register/money/deposit-withdraw';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of shops.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.companyId -
   */
  getShopsAction(parameters = {}) {
    let path = '/shops';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['companyId'] !== 'undefined') {
      queryParameters['companyId'] = parameters['companyId'];
    }

    queryParameters['companyId'] = this.transformParameter(
      queryParameters['companyId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get shop by id

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} shopId - Shop id
     */
  getShopAction(companyId, shopId, parameters = {}) {
    let path = '/{companyId}/shop/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return shops stats.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} shopId - Shop identifier
   * @param {} parameters.year - year of stats to get
   */
  getShopsStatsAction(companyId, shopId, parameters = {}) {
    let path = '/eom/{companyId}/stats/shop/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['year'] === 'undefined') {
      throw Error('Missing required parameter: year');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return eshop orders stats.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.year - year of stats to get
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   * @param {} parameters.brand -
   */
  getEshopOrdersStatsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders-stats';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return daily sales statistics of year
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.year - year of stats to get
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   * @param {} parameters.brand -
   */
  getDailySalesDataAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/daily-sales-stats';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of defined colorcards

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.q - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.toningSystemId - 
       * @param {} parameters.onlyFavorit - 
       * @param {} parameters.sorter - 
     */
  getColorcardsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['toningSystemId'] !== 'undefined') {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    queryParameters['toningSystemId'] = this.transformParameter(
      queryParameters['toningSystemId'],
    );

    if (typeof parameters['onlyFavorit'] !== 'undefined') {
      queryParameters['onlyFavorit'] = parameters['onlyFavorit'];
    }

    queryParameters['onlyFavorit'] = this.transformParameter(
      queryParameters['onlyFavorit'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new colorcard

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postColorcardAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of colorcards

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} colorcardId - Colorcard ID
       * @param {} parameters.removeDupliciteShades - 
       * @param {} parameters.productId - 
       * @param {} parameters.sendShades - 
     */
  getColorcardAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    if (typeof parameters['removeDupliciteShades'] !== 'undefined') {
      queryParameters['removeDupliciteShades'] =
        parameters['removeDupliciteShades'];
    }

    queryParameters['removeDupliciteShades'] = this.transformParameter(
      queryParameters['removeDupliciteShades'],
    );

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters['productId'] = this.transformParameter(
      queryParameters['productId'],
    );

    queryParameters['sendShades'] = true;

    if (typeof parameters['sendShades'] !== 'undefined') {
      queryParameters['sendShades'] = parameters['sendShades'];
    }

    queryParameters['sendShades'] = this.transformParameter(
      queryParameters['sendShades'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update details of colorcards

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} colorcardId - Colorcard ID
       * @param {} parameters.forced - 
       * @param {} parameters.body - 
     */
  putColorcardAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete colorcard

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} colorcardId - Colorcard ID
       * @param {} parameters.forced - 
     */
  deleteColorcardAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of colorcards

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} colorcardId - Colorcard ID
       * @param {} parameters.removeDupliciteShades - 
       * @param {} parameters.productId - 
       * @param {} parameters.sendShades - 
     */
  getB2BColorcardAction(colorcardId, parameters = {}) {
    let path = '/b2b/colorcards/{colorcardId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{colorcardId}', colorcardId);

    if (typeof parameters['removeDupliciteShades'] !== 'undefined') {
      queryParameters['removeDupliciteShades'] =
        parameters['removeDupliciteShades'];
    }

    queryParameters['removeDupliciteShades'] = this.transformParameter(
      queryParameters['removeDupliciteShades'],
    );

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters['productId'] = this.transformParameter(
      queryParameters['productId'],
    );

    queryParameters['sendShades'] = true;

    if (typeof parameters['sendShades'] !== 'undefined') {
      queryParameters['sendShades'] = parameters['sendShades'];
    }

    queryParameters['sendShades'] = this.transformParameter(
      queryParameters['sendShades'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return top 10 eshop products sales.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   * @param {} parameters.brand -
   * @param {} parameters.sorter -
   */
  getTopProductsSalesEshopAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/stats/eshop-products-sales';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    queryParameters['sorter'] = 'COUNT';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return top 10 shop products sales.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  getTopProductsSalesShopAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/stats/shop-products-sales';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return cash register stats of products sales.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.cashRegisterId -
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   */
  getCashRegisterSummary(companyId, parameters = {}) {
    let path = '/eom/{companyId}/stats/cash-register-sales';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['cashRegisterId'] !== 'undefined') {
      queryParameters['cashRegisterId'] = parameters['cashRegisterId'];
    }

    queryParameters['cashRegisterId'] = this.transformParameter(
      queryParameters['cashRegisterId'],
    );

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return stats of cash in cash register.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.cashRegisterId -
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   */
  getActualCashRegisterCashBalanceAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/stats/cash-register-cash';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['cashRegisterId'] !== 'undefined') {
      queryParameters['cashRegisterId'] = parameters['cashRegisterId'];
    }

    queryParameters['cashRegisterId'] = this.transformParameter(
      queryParameters['cashRegisterId'],
    );

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of colorcard shades

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} colorcardId - Colorcard ID
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
     */
  getColorcardShadesAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}/shades';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create colorcard shade

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} colorcardId - Colorcard ID
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.body - 
     */
  postColorcardShadeAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}/shade';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update colorcard shade

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} colorcardId - Colorcard ID
       * @param {} colorCode - ColorCode ID
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.body - 
     */
  putColorcardShadeAction(companyId, colorcardId, colorCode, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}/shade/{colorCode}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    path = path.replace('{colorCode}', colorCode);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete colorcard shade

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} colorcardId - Colorcard ID
       * @param {} colorCode - ColorCode ID
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  deleteColorcardShadeAction(
    companyId,
    colorcardId,
    colorCode,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}/shade/{colorCode}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    path = path.replace('{colorCode}', colorCode);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of defined toning systems

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.sorter - 
     */
  getToningSystemsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new toning system

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postToningSystemAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of formulas

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
     */
  getFormulasAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/formulas';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new formula

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postFormulaAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/formulas';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of formula

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} formulaId - Formula ID
     */
  getFormulaAction(companyId, formulaId, parameters = {}) {
    let path = '/crm/{companyId}/formulas/{formulaId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{formulaId}', formulaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update details of toning system

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} formulaId - Formula ID
       * @param {} parameters.body - 
     */
  putFormulaAction(companyId, formulaId, parameters = {}) {
    let path = '/crm/{companyId}/formulas/{formulaId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{formulaId}', formulaId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete formula

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} formulaId - Formula ID
     */
  deleteFormulaAction(companyId, formulaId, parameters = {}) {
    let path = '/crm/{companyId}/formulas/{formulaId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{formulaId}', formulaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of shade groups

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
     */
  getShadeGroups(parameters = {}) {
    let path = '/crm/shade-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of shade groups

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putShadeGroups(parameters = {}) {
    let path = '/crm/shade-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload Opportunity photo.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postColorCardTextureImageAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/colorcardsTexture';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete customer photo.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} fullPath - Escaped full path to photo
     */
  deleteColorCardImageAction(companyId, fullPath, parameters = {}) {
    let path = '/crm/{companyId}/colorcardsTexture/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get coloraunts by colorcard and color name,id.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.q - 
       * @param {} parameters.colocardsIds - 
     */
  getColorauntsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/coloraunts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['colocardsIds'] !== 'undefined') {
      queryParameters['colocardsIds'] = parameters['colocardsIds'];
    }

    queryParameters['colocardsIds'] = this.transformParameter(
      queryParameters['colocardsIds'],
    );

    if (typeof parameters['colocardsIds'] === 'undefined') {
      throw Error('Missing required parameter: colocardsIds');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get coloraunts by colorcard and color name,id.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.q - 
       * @param {} parameters.colocardsIds - 
     */
  getB2BColorauntsAction(parameters = {}) {
    let path = '/b2b/coloraunts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['colocardsIds'] !== 'undefined') {
      queryParameters['colocardsIds'] = parameters['colocardsIds'];
    }

    queryParameters['colocardsIds'] = this.transformParameter(
      queryParameters['colocardsIds'],
    );

    if (typeof parameters['colocardsIds'] === 'undefined') {
      throw Error('Missing required parameter: colocardsIds');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return products from innovatint/temaspeed db.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} systemId - Toning System ID
   * @param {} parameters.sorter -
   */
  getToningSystemExternalProductsAction(systemId, parameters = {}) {
    let path = '/codelist/toning-systems/{systemId}/external-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{systemId}', systemId);

    queryParameters['sorter'] = 'PRODUCTNAME';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of defined colorcards

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit for paginated results
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} productId - ID of product
       * @param {} parameters.removeDupliciteShades - 
     */
  getCatalogProductColorcardsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{productId}', productId);

    if (typeof parameters['removeDupliciteShades'] !== 'undefined') {
      queryParameters['removeDupliciteShades'] =
        parameters['removeDupliciteShades'];
    }

    queryParameters['removeDupliciteShades'] = this.transformParameter(
      queryParameters['removeDupliciteShades'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of defined colorcards

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit for paginated results
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} productId - ID of product
       * @param {} parameters.removeDupliciteShades - 
     */
  getB2BCatalogProductColorcardsAction(productId, parameters = {}) {
    let path = '/eshop/catalog-products/{productId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{productId}', productId);

    if (typeof parameters['removeDupliciteShades'] !== 'undefined') {
      queryParameters['removeDupliciteShades'] =
        parameters['removeDupliciteShades'];
    }

    queryParameters['removeDupliciteShades'] = this.transformParameter(
      queryParameters['removeDupliciteShades'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of of custom shades

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.q - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.onlyOwned - 
       * @param {} parameters.bases - 
     */
  getCustomShadesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['onlyOwned'] = true;

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    if (typeof parameters['bases'] !== 'undefined') {
      queryParameters['bases'] = parameters['bases'];
    }

    queryParameters['bases'] = this.transformParameter(
      queryParameters['bases'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new custom shade

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postCustomeShadeAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Details of formula

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customShadeId - Custom shade ID
     */
  getCustomShadeAction(companyId, customShadeId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades/{customShadeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customShadeId}', customShadeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update details of toning system

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customShadeId - Custom shade ID
       * @param {} parameters.body - 
     */
  putCustomShadeAction(companyId, customShadeId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades/{customShadeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customShadeId}', customShadeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete formula

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customShadeId - Custom shade ID
     */
  deleteCustomShadeAction(companyId, customShadeId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades/{customShadeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customShadeId}', customShadeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return paint systems for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   */
  getPaintSystemsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of catalog product for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} paintSystemId - ID of paint system
   */
  getPaintSystemAction(companyId, paintSystemId, parameters = {}) {
    let path = '/crm/{companyId}/paint-systems/{paintSystemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Products of paint system.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} paintSystemId - ID of paint system
   */
  getPaintSystemProductsAction(companyId, paintSystemId, parameters = {}) {
    let path = '/crm/{companyId}/paint-systems/{paintSystemId}/products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Paint system PDF file to download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} paintSystemId - ID of paint system
       * @param {} parameters.type - 
     */
  getPaintSystemDownloadAction(companyId, paintSystemId, parameters = {}) {
    let path = '/crm/{companyId}/paint-systems/{paintSystemId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters['type'] = 'pdf';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return paint systems for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   */
  getB2BPaintSystemsAction(parameters = {}) {
    let path = '/b2b/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Details of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} paintSystemId - ID of paint system
   */
  getB2BPaintSystemAction(paintSystemId, parameters = {}) {
    let path = '/b2b/paint-systems/{paintSystemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Products of paint system.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} paintSystemId - ID of paint system
   */
  getB2BPaintSystemProductsAction(paintSystemId, parameters = {}) {
    let path = '/b2b/paint-systems/{paintSystemId}/products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Paint system PDF file to download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} paintSystemId - ID of paint system
       * @param {} parameters.type - 
     */
  getB2BPaintSystemDownloadAction(paintSystemId, parameters = {}) {
    let path = '/b2b/paint-systems/{paintSystemId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters['type'] = 'pdf';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of security certificates types.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  getSecurityCertificatesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/security-certificates-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of security certificates types.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   */
  getB2BSecurityCertificatesAction(companyId, parameters = {}) {
    let path = '/b2b/{companyId}/security-certificates-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Save security certificate.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} parameters.body -
   */
  postCustomerSecurityCertificateAction(
    companyId,
    customerId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/security-certificates/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get security certificate for customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} parameters.onlyValid -
   * @param {} parameters.sorter -
   */
  getCustomerSecurityCertificatesAction(
    companyId,
    customerId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/security-certificates/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['onlyValid'] !== 'undefined') {
      queryParameters['onlyValid'] = parameters['onlyValid'];
    }

    queryParameters['onlyValid'] = this.transformParameter(
      queryParameters['onlyValid'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Save security certificate.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} parameters.body -
   */
  postB2BCustomerSecurityCertificateAction(
    companyId,
    customerId,
    parameters = {},
  ) {
    let path = '/b2b/{companyId}/security-certificates/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get security certificate for customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   * @param {} parameters.onlyValid -
   * @param {} parameters.sorter -
   */
  getB2BCustomerSecurityCertificatesAction(
    companyId,
    customerId,
    parameters = {},
  ) {
    let path = '/b2b/{companyId}/security-certificates/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['onlyValid'] !== 'undefined') {
      queryParameters['onlyValid'] = parameters['onlyValid'];
    }

    queryParameters['onlyValid'] = this.transformParameter(
      queryParameters['onlyValid'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get security certificate.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} certificateId - Certificate ID
   */
  getCustomerSecurityCertificateAction(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/security-certificates/{certificateId}/detail';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update customer certificate.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} certificateId - Certificate ID
   * @param {} parameters.body -
   */
  putCustomerSecurityCertificateAction(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/security-certificates/{certificateId}/detail';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete customer certificate.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} certificateId - Certificate ID
   * @param {} parameters.customerId -
   */
  deleteCustomerSecurityCertificateAction(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/security-certificates/{certificateId}/detail';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['customerId'] === 'undefined') {
      throw Error('Missing required parameter: customerId');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get security certificate.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} certificateId - Certificate ID
   */
  getCustomerSecurityCertificateAction_1(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path = '/b2b/{companyId}/security-certificates/{certificateId}/detail';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload security certificate PDF file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} certificateId - Certificate ID
       * @param {} parameters.body - 
     */
  postCustomerSecurityCertificatePDFAction(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/security-certificates/{certificateId}/upload';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload security certificate PDF file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} certificateId - Certificate ID
       * @param {} parameters.body - 
     */
  postB2BCustomerSecurityCertificatePDFAction(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path = '/b2b/{companyId}/security-certificates/{certificateId}/upload';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get security certificates files to download
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} certificateId - Certificate ID
   */
  getCustomerSecurityCertificateDownloadAction(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/security-certificates/{certificateId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get security certificates files to download
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} certificateId - Certificate ID
   */
  getCustomerSecurityCertificateDownloadAction_1(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path =
      '/b2b/{companyId}/security-certificates/{certificateId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Add security certificate approval.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} certificateId - Certificate ID
   * @param {} parameters.body -
   */
  putCustomerSecurityCertificateApprovalAction(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path = '/{companyId}/security-certificates/{certificateId}/approval';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Campaigns list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   * @param {} parameters.customerIds -
   * @param {} parameters.shopIds -
   * @param {} parameters.brandIds -
   * @param {} parameters.customerTypes -
   * @param {} parameters.productIds -
   * @param {} parameters.state -
   * @param {} parameters.onlyValid -
   * @param {} parameters.onlyOwned -
   */
  getCampaignsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['customerIds'] !== 'undefined') {
      queryParameters['customer_ids'] = parameters['customerIds'];
    }

    queryParameters['customer_ids'] = this.transformParameter(
      queryParameters['customer_ids'],
    );

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    if (typeof parameters['brandIds'] !== 'undefined') {
      queryParameters['brand_ids'] = parameters['brandIds'];
    }

    queryParameters['brand_ids'] = this.transformParameter(
      queryParameters['brand_ids'],
    );

    if (typeof parameters['customerTypes'] !== 'undefined') {
      queryParameters['customer_types'] = parameters['customerTypes'];
    }

    queryParameters['customer_types'] = this.transformParameter(
      queryParameters['customer_types'],
    );

    if (typeof parameters['productIds'] !== 'undefined') {
      queryParameters['product_ids'] = parameters['productIds'];
    }

    queryParameters['product_ids'] = this.transformParameter(
      queryParameters['product_ids'],
    );

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['onlyValid'] !== 'undefined') {
      queryParameters['onlyValid'] = parameters['onlyValid'];
    }

    queryParameters['onlyValid'] = this.transformParameter(
      queryParameters['onlyValid'],
    );

    if (typeof parameters['onlyOwned'] !== 'undefined') {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters['onlyOwned'] = this.transformParameter(
      queryParameters['onlyOwned'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create campaign.
-Alternative D - additional discount on everything. 
-Alternative G - Product for 0.01€ / 1 € with order. 
-Alternative H - Secont product with discount.
-Alternative I - Discount on selected products with order above certain price.
-Alternative J - Get selected product with order above certain price.
-Alternative K - Increase general discount for customer on selected products.
-Alternative L - Free product with order.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postCampaignAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Campaigns details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} campaignId - Campaign identifier
     */
  getCampaignAction(companyId, campaignId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/{campaignId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create campaign.
-Alternative D - additional discount on everything. 
-Alternative G - Product for 0.01€ / 1 € with order. 
-Alternative H - Secont product with discount.
-Alternative I - Discount on selected products with order above certain price.
-Alternative J - Get selected product with order above certain price.
-Alternative K - Increase general discount for customer on selected products.
-Alternative L - Free product with order.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} campaignId - Campaign identifier
       * @param {} parameters.body - 
     */
  putCampaignAction(companyId, campaignId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/{campaignId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} customerId - Customer ID
   */
  getCustomerCampaignsPdfAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/campaigns-pdf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get marketing details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} marketingListId - ID of marketing list
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getMarketingListContactsAction(companyId, marketingListId, parameters = {}) {
    let path = '/crm/{companyId}/marketing-list/{marketingListId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{marketingListId}', marketingListId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of contacts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.customersIds - 
       * @param {} parameters.customersSubtypes - 
       * @param {} parameters.active - 
       * @param {} parameters.sorter - 
       * @param {} parameters.q - 
     */
  getCustomersContactsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers-contacts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['customersIds'] !== 'undefined') {
      queryParameters['customersIds'] = parameters['customersIds'];
    }

    queryParameters['customersIds'] = this.transformParameter(
      queryParameters['customersIds'],
    );

    if (typeof parameters['customersSubtypes'] !== 'undefined') {
      queryParameters['customersSubtypes'] = parameters['customersSubtypes'];
    }

    queryParameters['customersSubtypes'] = this.transformParameter(
      queryParameters['customersSubtypes'],
    );

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload customer photo.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - 
     */
  postCustomerContractFileAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/contract-file';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Download contract file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getContractFileAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/contract-file';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete contract file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  deleteContractFileAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/contract-file';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of customer discounts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.year - 
     */
  getCustomerDiscountsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of discounts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - 
       * @param {} parameters.offset - 
       * @param {} parameters.sorter - 
       * @param {} parameters.type - 
       * @param {} parameters.onlyValid - 
     */
  getDiscountsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['onlyValid'] !== 'undefined') {
      queryParameters['onlyValid'] = parameters['onlyValid'];
    }

    queryParameters['onlyValid'] = this.transformParameter(
      queryParameters['onlyValid'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create discount.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postDiscountAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create/update multiple discounts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  putMultipleDiscountsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update discount details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} discountId - ID of discount
       * @param {} parameters.body - 
     */
  putDiscountAction(companyId, discountId, parameters = {}) {
    let path = '/crm/{companyId}/discounts/{discountId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{discountId}', discountId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete discount.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} discountId - ID of discount
     */
  deleteDiscountAction(companyId, discountId, parameters = {}) {
    let path = '/crm/{companyId}/discounts/{discountId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{discountId}', discountId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update discount details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  putDiscountsApprovalRequestDecisionAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/discounts-approval-request-decision';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Start synchronization discounts between oxyrion and navision.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.syncDirection - 
     */
  postStartDiscountsSynchronizationAction(
    companyId,
    customerId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/customers/{customerId}/synchronize-discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['syncDirection'] !== 'undefined') {
      queryParameters['syncDirection'] = parameters['syncDirection'];
    }

    queryParameters['syncDirection'] = this.transformParameter(
      queryParameters['syncDirection'],
    );

    if (typeof parameters['syncDirection'] === 'undefined') {
      throw Error('Missing required parameter: syncDirection');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Request packages orders pickup.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.packagesIds -
   * @param {} parameters.packagesId -
   */
  postPacketaRequestPickupsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packeta/packages-request-pickups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['packagesIds'] !== 'undefined') {
      queryParameters['packagesIds'] = parameters['packagesIds'];
    }

    queryParameters['packagesIds'] = this.transformParameter(
      queryParameters['packagesIds'],
    );

    if (typeof parameters['packagesId'] !== 'undefined') {
      queryParameters['packagesId'] = parameters['packagesId'];
    }

    queryParameters['packagesId'] = this.transformParameter(
      queryParameters['packagesId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order comments.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.packagesIds -
   */
  getPacketaLabelsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packeta/packages-labels';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['packagesIds'] !== 'undefined') {
      queryParameters['packagesIds'] = parameters['packagesIds'];
    }

    queryParameters['packagesIds'] = this.transformParameter(
      queryParameters['packagesIds'],
    );

    if (typeof parameters['packagesIds'] === 'undefined') {
      throw Error('Missing required parameter: packagesIds');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order Packeta packages.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  getPacketaOrderPackagesAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/packeta/orders/{orderId}/packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create eshop oorder package to Packeta.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.body -
   */
  postPacketaPackageAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/packeta/orders/{orderId}/packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get all Packeta packages.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit for paginated results
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.state -
   * @param {} parameters.orderNo -
   * @param {} parameters.orderState -
   * @param {} parameters.orderDateFrom -
   * @param {} parameters.orderDateTo -
   * @param {} parameters.priceFrom -
   * @param {} parameters.priceTo -
   * @param {} parameters.paymentType -
   * @param {} parameters.deliveryType -
   * @param {} parameters.customerId -
   * @param {} parameters.originShop -
   * @param {} parameters.sorter -
   */
  getPacketaPackagesAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packeta/orders-packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['orderNo'] !== 'undefined') {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    queryParameters['orderNo'] = this.transformParameter(
      queryParameters['orderNo'],
    );

    if (typeof parameters['orderState'] !== 'undefined') {
      queryParameters['orderState'] = parameters['orderState'];
    }

    queryParameters['orderState'] = this.transformParameter(
      queryParameters['orderState'],
    );

    if (typeof parameters['orderDateFrom'] !== 'undefined') {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    queryParameters['orderDateFrom'] = this.transformParameter(
      queryParameters['orderDateFrom'],
    );

    if (typeof parameters['orderDateTo'] !== 'undefined') {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    queryParameters['orderDateTo'] = this.transformParameter(
      queryParameters['orderDateTo'],
    );

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    queryParameters['priceFrom'] = this.transformParameter(
      queryParameters['priceFrom'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    queryParameters['priceTo'] = this.transformParameter(
      queryParameters['priceTo'],
    );

    if (typeof parameters['paymentType'] !== 'undefined') {
      queryParameters['paymentType'] = parameters['paymentType'];
    }

    queryParameters['paymentType'] = this.transformParameter(
      queryParameters['paymentType'],
    );

    if (typeof parameters['deliveryType'] !== 'undefined') {
      queryParameters['deliveryType'] = parameters['deliveryType'];
    }

    queryParameters['deliveryType'] = this.transformParameter(
      queryParameters['deliveryType'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['originShop'] !== 'undefined') {
      queryParameters['originShop'] = parameters['originShop'];
    }

    queryParameters['originShop'] = this.transformParameter(
      queryParameters['originShop'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Request pickup Packeta package.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} packageId - ID curier pacakge
   */
  postPacketaRequestPickupAction(
    companyId,
    orderId,
    packageId,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/packeta/orders/{orderId}/packages/{packageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{packageId}', packageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete order Packeta package.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} packageId - ID curier pacakge
   */
  deletePacketaPackageAction(companyId, orderId, packageId, parameters = {}) {
    let path = '/eom/{companyId}/packeta/orders/{orderId}/packages/{packageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{packageId}', packageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Request pickup Packeta package.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.dateOfDay -
   */
  getPacketaDailySummaryAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packeta/packages/daily-summary';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['dateOfDay'] !== 'undefined') {
      queryParameters['dateOfDay'] = parameters['dateOfDay'];
    }

    queryParameters['dateOfDay'] = this.transformParameter(
      queryParameters['dateOfDay'],
    );

    if (typeof parameters['dateOfDay'] === 'undefined') {
      throw Error('Missing required parameter: dateOfDay');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get tracking packet informations.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} packetId - Company identifier
   */
  getPacketaPacketTrackingInformationAction(
    companyId,
    packetId,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/packeta/{packetId}/tracking-informations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{packetId}', packetId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Send 123Courier confimation email to customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.forced -
   * @param {} parameters.courierType -
   */
  sendEshopOrderCourierEmailAction(companyId, orderId, parameters = {}) {
    let path =
      '/eom/{companyId}/packeta/orders/{orderId}/courier-confirmation-email';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    queryParameters['courierType'] = 'PACKETA';

    if (typeof parameters['courierType'] !== 'undefined') {
      queryParameters['courierType'] = parameters['courierType'];
    }

    queryParameters['courierType'] = this.transformParameter(
      queryParameters['courierType'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Request 123courier orders pickup.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.packagesIds -
   * @param {} parameters.packagesId -
   */
  post123CourierRequestPickupsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packages-request-pickups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['packagesIds'] !== 'undefined') {
      queryParameters['packagesIds'] = parameters['packagesIds'];
    }

    queryParameters['packagesIds'] = this.transformParameter(
      queryParameters['packagesIds'],
    );

    if (typeof parameters['packagesId'] !== 'undefined') {
      queryParameters['packagesId'] = parameters['packagesId'];
    }

    queryParameters['packagesId'] = this.transformParameter(
      queryParameters['packagesId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order 123Curier packages.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  getOrder123CurierPackagesAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create eshop oorder package to 123Courier.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.body -
   */
  post123CourierPackageAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order comments.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.packagesIds -
   * @param {} parameters.packagesId -
   * @param {} parameters.position -
   */
  get123CurierTicketsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packages-tickets';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['packagesIds'] !== 'undefined') {
      queryParameters['packagesIds'] = parameters['packagesIds'];
    }

    queryParameters['packagesIds'] = this.transformParameter(
      queryParameters['packagesIds'],
    );

    if (typeof parameters['packagesId'] !== 'undefined') {
      queryParameters['packagesId'] = parameters['packagesId'];
    }

    queryParameters['packagesId'] = this.transformParameter(
      queryParameters['packagesId'],
    );

    if (typeof parameters['position'] !== 'undefined') {
      queryParameters['position'] = parameters['position'];
    }

    queryParameters['position'] = this.transformParameter(
      queryParameters['position'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get all 123 curier packages.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit for paginated results
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.state -
   * @param {} parameters.orderNo -
   * @param {} parameters.orderState -
   * @param {} parameters.orderDateFrom -
   * @param {} parameters.orderDateTo -
   * @param {} parameters.priceFrom -
   * @param {} parameters.priceTo -
   * @param {} parameters.paymentType -
   * @param {} parameters.deliveryType -
   * @param {} parameters.customerId -
   * @param {} parameters.originShop -
   * @param {} parameters.sorter -
   */
  get123CurierPackagesAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders-packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['orderNo'] !== 'undefined') {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    queryParameters['orderNo'] = this.transformParameter(
      queryParameters['orderNo'],
    );

    if (typeof parameters['orderState'] !== 'undefined') {
      queryParameters['orderState'] = parameters['orderState'];
    }

    queryParameters['orderState'] = this.transformParameter(
      queryParameters['orderState'],
    );

    if (typeof parameters['orderDateFrom'] !== 'undefined') {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    queryParameters['orderDateFrom'] = this.transformParameter(
      queryParameters['orderDateFrom'],
    );

    if (typeof parameters['orderDateTo'] !== 'undefined') {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    queryParameters['orderDateTo'] = this.transformParameter(
      queryParameters['orderDateTo'],
    );

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    queryParameters['priceFrom'] = this.transformParameter(
      queryParameters['priceFrom'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    queryParameters['priceTo'] = this.transformParameter(
      queryParameters['priceTo'],
    );

    if (typeof parameters['paymentType'] !== 'undefined') {
      queryParameters['paymentType'] = parameters['paymentType'];
    }

    queryParameters['paymentType'] = this.transformParameter(
      queryParameters['paymentType'],
    );

    if (typeof parameters['deliveryType'] !== 'undefined') {
      queryParameters['deliveryType'] = parameters['deliveryType'];
    }

    queryParameters['deliveryType'] = this.transformParameter(
      queryParameters['deliveryType'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['originShop'] !== 'undefined') {
      queryParameters['originShop'] = parameters['originShop'];
    }

    queryParameters['originShop'] = this.transformParameter(
      queryParameters['originShop'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Request pickup 123Curier package.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} packageId - ID curier pacakge
   */
  post123CourierRequestPickupAction(
    companyId,
    orderId,
    packageId,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/orders/{orderId}/packages/{packageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{packageId}', packageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete order 123Curier package.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} packageId - ID curier pacakge
   */
  delete123CourierPackageAction(
    companyId,
    orderId,
    packageId,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/orders/{orderId}/packages/{packageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{packageId}', packageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return packages daily summary.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.dateOfDay -
   */
  get123CurierDailySummaryAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packages/daily-summary';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['dateOfDay'] !== 'undefined') {
      queryParameters['dateOfDay'] = parameters['dateOfDay'];
    }

    queryParameters['dateOfDay'] = this.transformParameter(
      queryParameters['dateOfDay'],
    );

    if (typeof parameters['dateOfDay'] === 'undefined') {
      throw Error('Missing required parameter: dateOfDay');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return package tracking.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  get123CurierDeliveryRedirectAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/{orderId}/delivery-redirect';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'text/html');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return registered customers list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   * @param {} parameters.cashRegisterId -
   */
  getRegisterCustomersAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/customer-register-list';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['cashRegisterId'] !== 'undefined') {
      queryParameters['cashRegisterId'] = parameters['cashRegisterId'];
    }

    queryParameters['cashRegisterId'] = this.transformParameter(
      queryParameters['cashRegisterId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Register customer through EOM.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postRegisterCustomer(companyId, parameters = {}) {
    let path = '/eom/{companyId}/customer-register';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return cash register orders list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.sorter -
   * @param {} parameters.orderNo -
   * @param {} parameters.customerId -
   * @param {} parameters.cashRegisterId -
   */
  getCashRegisterOrdersAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/cash-register-orders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['orderNo'] !== 'undefined') {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    queryParameters['orderNo'] = this.transformParameter(
      queryParameters['orderNo'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['cashRegisterId'] !== 'undefined') {
      queryParameters['cashRegisterId'] = parameters['cashRegisterId'];
    }

    queryParameters['cashRegisterId'] = this.transformParameter(
      queryParameters['cashRegisterId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return cash register order detail.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  getCashRegisterOrderDetailAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/cash-register-orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get EOM invoice pdf file download link

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} invoiceId - Invoice identifier
     */
  getSalesInvoicePdfAction(companyId, invoiceId, parameters = {}) {
    let path = '/eom/{companyId}/cash-register-orders/{invoiceId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get EOM shipment pdf file download link

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} shipId - Shipment identifier
     */
  getSalesShipmentPdfAction(companyId, shipId, parameters = {}) {
    let path = '/eom/{companyId}/cash-register-orders/{shipId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shipId}', shipId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Export orders to csv file.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   * @param {} parameters.orderNo -
   * @param {} parameters.orderState -
   * @param {} parameters.orderDateFrom -
   * @param {} parameters.orderDateTo -
   * @param {} parameters.priceFrom -
   * @param {} parameters.priceTo -
   * @param {} parameters.paymentType -
   * @param {} parameters.deliveryType -
   * @param {} parameters.originShop -
   * @param {} parameters.customerId -
   */
  exportEshopOrdersToCSVAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders-export-csv';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['orderNo'] !== 'undefined') {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    queryParameters['orderNo'] = this.transformParameter(
      queryParameters['orderNo'],
    );

    if (typeof parameters['orderState'] !== 'undefined') {
      queryParameters['orderState'] = parameters['orderState'];
    }

    queryParameters['orderState'] = this.transformParameter(
      queryParameters['orderState'],
    );

    if (typeof parameters['orderDateFrom'] !== 'undefined') {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    queryParameters['orderDateFrom'] = this.transformParameter(
      queryParameters['orderDateFrom'],
    );

    if (typeof parameters['orderDateTo'] !== 'undefined') {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    queryParameters['orderDateTo'] = this.transformParameter(
      queryParameters['orderDateTo'],
    );

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    queryParameters['priceFrom'] = this.transformParameter(
      queryParameters['priceFrom'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    queryParameters['priceTo'] = this.transformParameter(
      queryParameters['priceTo'],
    );

    if (typeof parameters['paymentType'] !== 'undefined') {
      queryParameters['paymentType'] = parameters['paymentType'];
    }

    queryParameters['paymentType'] = this.transformParameter(
      queryParameters['paymentType'],
    );

    if (typeof parameters['deliveryType'] !== 'undefined') {
      queryParameters['deliveryType'] = parameters['deliveryType'];
    }

    queryParameters['deliveryType'] = this.transformParameter(
      queryParameters['deliveryType'],
    );

    if (typeof parameters['originShop'] !== 'undefined') {
      queryParameters['originShop'] = parameters['originShop'];
    }

    queryParameters['originShop'] = this.transformParameter(
      queryParameters['originShop'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update internal order state.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.body -
   */
  putEshopOrderAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/internal-state';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return orders list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   * @param {} parameters.orderNo -
   * @param {} parameters.orderState -
   * @param {} parameters.eomState -
   * @param {} parameters.orderDateFrom -
   * @param {} parameters.orderDateTo -
   * @param {} parameters.priceFrom -
   * @param {} parameters.priceTo -
   * @param {} parameters.paymentType -
   * @param {} parameters.deliveryType -
   * @param {} parameters.originShop -
   * @param {} parameters.customerId -
   * @param {} parameters.voucherCode -
   * @param {} parameters.excludeTest -
   */
  getEshopOrdersAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['orderNo'] !== 'undefined') {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    queryParameters['orderNo'] = this.transformParameter(
      queryParameters['orderNo'],
    );

    if (typeof parameters['orderState'] !== 'undefined') {
      queryParameters['orderState'] = parameters['orderState'];
    }

    queryParameters['orderState'] = this.transformParameter(
      queryParameters['orderState'],
    );

    if (typeof parameters['eomState'] !== 'undefined') {
      queryParameters['eomState'] = parameters['eomState'];
    }

    queryParameters['eomState'] = this.transformParameter(
      queryParameters['eomState'],
    );

    if (typeof parameters['orderDateFrom'] !== 'undefined') {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    queryParameters['orderDateFrom'] = this.transformParameter(
      queryParameters['orderDateFrom'],
    );

    if (typeof parameters['orderDateTo'] !== 'undefined') {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    queryParameters['orderDateTo'] = this.transformParameter(
      queryParameters['orderDateTo'],
    );

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    queryParameters['priceFrom'] = this.transformParameter(
      queryParameters['priceFrom'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    queryParameters['priceTo'] = this.transformParameter(
      queryParameters['priceTo'],
    );

    if (typeof parameters['paymentType'] !== 'undefined') {
      queryParameters['paymentType'] = parameters['paymentType'];
    }

    queryParameters['paymentType'] = this.transformParameter(
      queryParameters['paymentType'],
    );

    if (typeof parameters['deliveryType'] !== 'undefined') {
      queryParameters['deliveryType'] = parameters['deliveryType'];
    }

    queryParameters['deliveryType'] = this.transformParameter(
      queryParameters['deliveryType'],
    );

    if (typeof parameters['originShop'] !== 'undefined') {
      queryParameters['originShop'] = parameters['originShop'];
    }

    queryParameters['originShop'] = this.transformParameter(
      queryParameters['originShop'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['voucherCode'] !== 'undefined') {
      queryParameters['voucherCode'] = parameters['voucherCode'];
    }

    queryParameters['voucherCode'] = this.transformParameter(
      queryParameters['voucherCode'],
    );

    if (typeof parameters['excludeTest'] !== 'undefined') {
      queryParameters['excludeTest'] = parameters['excludeTest'];
    }

    queryParameters['excludeTest'] = this.transformParameter(
      queryParameters['excludeTest'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order detail.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  getEshopOrderAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create invoice of order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  postInvoiceOrderAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/invoice';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create shipment and invoice of order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  postShipmentInvoiceOrderAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/shipment-invoice';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update billing address of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.body - Address to update.
   */
  putEshopOrderBillingAddressAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/billing-address';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update payment status.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  putEshopOrderPaymentConfirmAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/confirm-payment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update internal note of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.body - Address to update.
   */
  putEshopOrderInternalNoteAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/internal-note';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update delivery address of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.body - Address to update.
   */
  putEshopOrderDeliveryAddressAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/delivery-address';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update estimate delivery date of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.body - Data to update.
   */
  putEshopOrderDeliveryDateAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/estimate-delivery-date';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order comments.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  getEshopOrderCommentsAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/comment-item';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Create eshop order comment item.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.notifyUserByEmail -
   * @param {} parameters.notifyUserByMessage -
   * @param {} parameters.body -
   */
  postEshopOrderCommentAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/comment-item';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['notifyUserByEmail'] !== 'undefined') {
      queryParameters['notifyUserByEmail'] = parameters['notifyUserByEmail'];
    }

    queryParameters['notifyUserByEmail'] = this.transformParameter(
      queryParameters['notifyUserByEmail'],
    );

    if (typeof parameters['notifyUserByMessage'] !== 'undefined') {
      queryParameters['notifyUserByMessage'] =
        parameters['notifyUserByMessage'];
    }

    queryParameters['notifyUserByMessage'] = this.transformParameter(
      queryParameters['notifyUserByMessage'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Send order confimation email to customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.forced -
   * @param {} parameters.email -
   */
  sendEshopOrderConfirmationEmailAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/confirmation-email';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw Error('Missing required parameter: email');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get email logs for order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.sorter -
   */
  getEshopOrderEmailLogsAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/email-logs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return orders messages list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   * @param {} parameters.orderNo -
   * @param {} parameters.orderState -
   * @param {} parameters.customerId -
   * @param {} parameters.createdBy -
   */
  getEshopMessagesAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/messages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['orderNo'] !== 'undefined') {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    queryParameters['orderNo'] = this.transformParameter(
      queryParameters['orderNo'],
    );

    if (typeof parameters['orderState'] !== 'undefined') {
      queryParameters['orderState'] = parameters['orderState'];
    }

    queryParameters['orderState'] = this.transformParameter(
      queryParameters['orderState'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['createdBy'] !== 'undefined') {
      queryParameters['createdBy'] = parameters['createdBy'];
    }

    queryParameters['createdBy'] = this.transformParameter(
      queryParameters['createdBy'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Update eshop order eom state.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   * @param {} parameters.body - New state to set.
   */
  putEshopOrderStateAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/change-state';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get EOM order file download id.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} orderId - ID of order
       * @param {} fullPath - Escaped full path to attachment
     */
  getEomOrderFileDownloadIdAction(
    companyId,
    orderId,
    fullPath,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/orders/{orderId}/files/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete EOM order file

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} orderId - ID of order
       * @param {} fullPath - Escaped full path to attachment
     */
  deleteEomOrderFileAction(companyId, orderId, fullPath, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/files/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload EOM order file

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} orderId - ID of order
       * @param {} parameters.body - 
     */
  postEomOrderFileAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order ratings list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   * @param {} parameters.orderNo -
   * @param {} parameters.customerId -
   * @param {} parameters.rating -
   * @param {} parameters.orderDateFrom -
   * @param {} parameters.orderDateTo -
   */
  getEshopOrderRatingsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders-ratings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['orderNo'] !== 'undefined') {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    queryParameters['orderNo'] = this.transformParameter(
      queryParameters['orderNo'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['rating'] !== 'undefined') {
      queryParameters['rating'] = parameters['rating'];
    }

    queryParameters['rating'] = this.transformParameter(
      queryParameters['rating'],
    );

    if (typeof parameters['orderDateFrom'] !== 'undefined') {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    queryParameters['orderDateFrom'] = this.transformParameter(
      queryParameters['orderDateFrom'],
    );

    if (typeof parameters['orderDateTo'] !== 'undefined') {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    queryParameters['orderDateTo'] = this.transformParameter(
      queryParameters['orderDateTo'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order rating.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} orderId - ID of order
   */
  getEshopOrderRatingAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders-rating/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return warranty claims list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.sorter -
   * @param {} parameters.createdDateFrom -
   * @param {} parameters.resultSolution -
   * @param {} parameters.customerId -
   * @param {} parameters.state -
   * @param {} parameters.type -
   * @param {} parameters.result -
   */
  getEshopWarrantyClaimsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['createdDateFrom'] !== 'undefined') {
      queryParameters['createdDateFrom'] = parameters['createdDateFrom'];
    }

    queryParameters['createdDateFrom'] = this.transformParameter(
      queryParameters['createdDateFrom'],
    );

    if (typeof parameters['resultSolution'] !== 'undefined') {
      queryParameters['resultSolution'] = parameters['resultSolution'];
    }

    queryParameters['resultSolution'] = this.transformParameter(
      queryParameters['resultSolution'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters['customerId'] = this.transformParameter(
      queryParameters['customerId'],
    );

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['result'] !== 'undefined') {
      queryParameters['result'] = parameters['result'];
    }

    queryParameters['result'] = this.transformParameter(
      queryParameters['result'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return warranty claim detail.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} claimId - ID of order
   */
  getEshopWarrantyClaimAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update warranty claim status

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} claimId - ID of order
       * @param {} parameters.body - 
     */
  putEshopWarrantyClaimStateAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Insert claim note value.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} claimId - ID of order
   * @param {} parameters.body -
   */
  postEshopWarrantyClaimNoteAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}/notes';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get warranty claim file download id.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} claimId - ID of order
       * @param {} fullPath - Escaped full path to attachment
     */
  getEshopWarrantyClaimFileDownloadIdAction(
    companyId,
    claimId,
    fullPath,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}/files/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload warranty claim file

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} claimId - ID of order
       * @param {} parameters.body - 
     */
  postEshopWarrantyClaimFileAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Storno warranty claim

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} claimId - ID of order
     */
  cancelWarrantyClaimAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/storno/{claimId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Add response to question
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} questionId -
   * @param {} parameters.body -
   */
  postProductQuestionResponseAction(companyId, questionId, parameters = {}) {
    let path = '/{companyId}/products/question/{questionId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{questionId}', questionId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Question detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} questionId -
   */
  getProductQuestionAction(companyId, questionId, parameters = {}) {
    let path = '/{companyId}/products/question/{questionId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{questionId}', questionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete question
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} questionId -
   */
  deleteProductQuestionAction(companyId, questionId, parameters = {}) {
    let path = '/{companyId}/products/question/{questionId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{questionId}', questionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Add response to question
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} responseId -
   * @param {} questionId -
   * @param {} parameters.body -
   */
  putProductQuestionResponseAction(
    companyId,
    responseId,
    questionId,
    parameters = {},
  ) {
    let path =
      '/{companyId}/products/question/{questionId}/repsonse/{responseId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{responseId}', responseId);

    path = path.replace('{questionId}', questionId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of questions for product
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.sorter -
   */
  getProductQuestionsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products/questions';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Product reviews list
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.sorter -
   * @param {} parameters.productId -
   * @param {} parameters.contactId -
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   * @param {} parameters.rating -
   */
  getEshopProductsReviewsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products/reviews';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters['productId'] = this.transformParameter(
      queryParameters['productId'],
    );

    if (typeof parameters['contactId'] !== 'undefined') {
      queryParameters['contactId'] = parameters['contactId'];
    }

    queryParameters['contactId'] = this.transformParameter(
      queryParameters['contactId'],
    );

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    if (typeof parameters['rating'] !== 'undefined') {
      queryParameters['rating'] = parameters['rating'];
    }

    queryParameters['rating'] = this.transformParameter(
      queryParameters['rating'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get product review detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} reviewId -
   */
  getEshopProductReviewDetailAction(companyId, reviewId, parameters = {}) {
    let path = '/{companyId}/products/reviews/{reviewId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reviewId}', reviewId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete product review
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} reviewId -
   */
  deleteEshopProductReviewAction(companyId, reviewId, parameters = {}) {
    let path = '/{companyId}/products/reviews/{reviewId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reviewId}', reviewId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Aproval product review
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} reviewId -
   * @param {} parameters.approval -
   */
  putReviewAprovalRequestAction(companyId, reviewId, parameters = {}) {
    let path = '/{companyId}/products/reviews/{reviewId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reviewId}', reviewId);

    if (typeof parameters['approval'] !== 'undefined') {
      queryParameters['approval'] = parameters['approval'];
    }

    queryParameters['approval'] = this.transformParameter(
      queryParameters['approval'],
    );

    if (typeof parameters['approval'] === 'undefined') {
      throw Error('Missing required parameter: approval');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return newsletters for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   * @param {} parameters.sorter -
   */
  getNewslettersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/newsletters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of newsletters sent to customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.sorter - 
     */
  getCustomerNewsletters(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/newsletters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} newsletterId -
   */
  getNewsletterAction(companyId, newsletterId, parameters = {}) {
    let path = '/crm/{companyId}/newsletters/{newsletterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsletterId}', newsletterId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} newsletterId -
   */
  getNewsletterPdfAction(companyId, newsletterId, parameters = {}) {
    let path = '/crm/{companyId}/newsletters/{newsletterId}/newsletters-pdf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsletterId}', newsletterId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return list of notifications.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.q -
   * @param {} parameters.filters -
   * @param {} parameters.sorter -
   */
  getNotificationsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/notifications';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new notification.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postNotificationAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/notifications';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return detail of notification.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} notificationId -
   */
  getNotificationAction(companyId, notificationId, parameters = {}) {
    let path = '/crm/{companyId}/notifications/{notificationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{notificationId}', notificationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update notification.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} notificationId - 
       * @param {} parameters.body - 
     */
  putNotificationAction(companyId, notificationId, parameters = {}) {
    let path = '/crm/{companyId}/notifications/{notificationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{notificationId}', notificationId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete notification.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} notificationId - 
     */
  deleteNotificationAction(companyId, notificationId, parameters = {}) {
    let path = '/crm/{companyId}/notifications/{notificationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{notificationId}', notificationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return list of notifications for user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {} parameters.sorter -
   */
  getUserNotificationsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/user-notifications';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return detail of user notification.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} notificationId -
   */
  getUserNotificationAction(companyId, notificationId, parameters = {}) {
    let path = '/crm/{companyId}/user-notifications/{notificationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{notificationId}', notificationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update notification for user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} notificationId - 
     */
  postUserNotificationOpenAction(companyId, notificationId, parameters = {}) {
    let path = '/crm/{companyId}/user-notifications/{notificationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{notificationId}', notificationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get customer actual turnover.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.year - Year
     */
  getCustomerActualTurnover(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/actual-turnover';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Vouchers for customer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
     */
  getCustomerVouchersAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/vouchers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return list of used vouchers in orders.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.q -
   */
  getEshopUsedVouchersAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders/used-vouchers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return list of stats used vouchers.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} parameters.limit - Limit parameter
   * @param {} parameters.offset - Offset parameter
   * @param {array} parameters.voucherCodes -
   * @param {} parameters.fromDate -
   * @param {} parameters.toDate -
   */
  getEshopUserVouchersStatsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders/used-vouchers-stats';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['voucherCodes'] !== 'undefined') {
      queryParameters['voucherCodes'] = parameters['voucherCodes'];
    }

    queryParameters['voucherCodes'] = this.transformParameter(
      queryParameters['voucherCodes'],
    );

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    queryParameters['fromDate'] = this.transformParameter(
      queryParameters['fromDate'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters['toDate'] = this.transformParameter(
      queryParameters['toDate'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Ownership management.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} parameters.body - 
     */
  putCustomerOwnershipAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/ownership';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * delete user ownership by userId.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} customerId - Customer ID
       * @param {} userId - ID of user
     */
  deleteCustomerOwnershipAction(
    companyId,
    customerId,
    userId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/customers/{customerId}/ownership/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get multimedia for CRM.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} companyId - Company identifier
       * @param {} parameters.type - Filter by type
       * @param {} parameters.filters - 
       * @param {} parameters.productId - 
     */
  getMultimediaAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/multimedia';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters['productId'] = this.transformParameter(
      queryParameters['productId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get multimedia file to download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} multimediaId - ID of order
     */
  getMultimediaDownloadAction(companyId, multimediaId, parameters = {}) {
    let path = '/crm/{companyId}/multimedia/{multimediaId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get multimedia for B2B.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.type - Filter by type
       * @param {} parameters.filters - 
       * @param {} parameters.productId - 
     */
  getB2BMultimediaAction(parameters = {}) {
    let path = '/b2b/multimedia';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters['productId'] = this.transformParameter(
      queryParameters['productId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get multimedia file to download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} multimediaId - ID of order
     */
  getB2BMultimediaDownloadAction(multimediaId, parameters = {}) {
    let path = '/b2b/multimedia/{multimediaId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of marketing materials.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.filters - 
     */
  getMarketingMaterialsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/marketing-materials';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get marketing material for CRM.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} materialId - Id of marketing matrial
     */
  getMarketingMaterialAction(companyId, materialId, parameters = {}) {
    let path = '/crm/{companyId}/marketing-materials/{materialId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{materialId}', materialId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of marketing materials.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - 
       * @param {} parameters.filters - 
     */
  getB2BMarketingMaterialsAction(parameters = {}) {
    let path = '/b2b/marketing-materials';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get marketing material for CRM.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} materialId - Id of marketing matrial
     */
  getB2BMarketingMaterialAction(materialId, parameters = {}) {
    let path = '/b2b/marketing-materials/{materialId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{materialId}', materialId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get business info based on ICO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId - Company identifier
   * @param {} ico - ICO na vyhladanie
   */
  getBusinessInfo(companyId, ico, parameters = {}) {
    let path = '/crm/{companyId}/business-info/{ico}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{ico}', ico);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Start synchronization catalog product for eshop and B2B

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} catalogProductId - ID of catalog product
     */
  postSyncProductAction(catalogProductId, parameters = {}) {
    let path = '/synchronizations/eshop-product/{catalogProductId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{catalogProductId}', catalogProductId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Start synchronization of aloglia index update

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} algoliaIndexType - Algolia index type which to update
     */
  updateAllAlgoliaProducts(companyId, algoliaIndexType, parameters = {}) {
    let path =
      '/crm/{companyId}/update-algolia-products-all/{algoliaIndexType}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{algoliaIndexType}', algoliaIndexType);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Start synchronization of aloglia index update

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} algoliaIndexType - Algolia index type which to update
     */
  updateLatestAlgoliaProducts(companyId, algoliaIndexType, parameters = {}) {
    let path =
      '/crm/{companyId}/update-algolia-products-latest/{algoliaIndexType}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{algoliaIndexType}', algoliaIndexType);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Send push notification.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  sendPushNotificationAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/sendPushNotification';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Dejmark company info

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
     */
  getDejmarkCompanyInfo(companyId, parameters = {}) {
    let path = '/dejmark/{companyId}/company/info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Dejmark portfolio brands

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
     */
  getDejmarkProductsPortofliosAction(companyId, parameters = {}) {
    let path = '/dejmark/{companyId}/brands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Dejmark company brand ID

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} brandId - Brand id
     */
  getDejmarkProductPortofliosAction(companyId, brandId, parameters = {}) {
    let path = '/dejmark/{companyId}/brands/{brandId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brandId}', brandId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Dejmark shops

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.brand - Filter by brand name
       * @param {} parameters.latitude - Latitude of the shop
       * @param {} parameters.longitude - Longitude of the shop
       * @param {} parameters.maxDistance - Radius from location
     */
  getDejmarkShopsAction(companyId, parameters = {}) {
    let path = '/dejmark/{companyId}/shops';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    if (typeof parameters['latitude'] !== 'undefined') {
      queryParameters['latitude'] = parameters['latitude'];
    }

    queryParameters['latitude'] = this.transformParameter(
      queryParameters['latitude'],
    );

    if (typeof parameters['longitude'] !== 'undefined') {
      queryParameters['longitude'] = parameters['longitude'];
    }

    queryParameters['longitude'] = this.transformParameter(
      queryParameters['longitude'],
    );

    if (typeof parameters['maxDistance'] !== 'undefined') {
      queryParameters['maxDistance'] = parameters['maxDistance'];
    }

    queryParameters['maxDistance'] = this.transformParameter(
      queryParameters['maxDistance'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Dejmark published news.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.lang - 
     */
  getDejmarkNewsListAction(companyId, parameters = {}) {
    let path = '/dejmark/{companyId}/news';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Dejmark news detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news
       * @param {} companyId - Company identifier
     */
  getDejmarkNewsAction(newsId, companyId, parameters = {}) {
    let path = '/dejmark/{companyId}/news/{newsId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update counter of Dejmark news.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} newsId - ID of news
     */
  putDejmarkNewsOpenAction(companyId, newsId, parameters = {}) {
    let path = '/dejmark/{companyId}/news/{newsId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsId}', newsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Post contact form from Dejmark

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postDejmarkContactFormAction(companyId, parameters = {}) {
    let path = '/dejmark/{companyId}/contact';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Dejmark banners

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.country - Search by country
     */
  getDejmarkBannersAction(parameters = {}) {
    let path = '/dejmark/{companyId}/banners';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  openapiJson(parameters = {}) {
    let path = '/openapi.json';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new Api();
export { Api };

import React, { PureComponent } from 'react';
import Theme from 'oxyrion-ui/lib/Themes/themeEOM';
import {
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
  Label,
} from 'recharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { formatPrice, __ } from '../../Utils';

export default class LineChartStatsComponent extends PureComponent {
  formatXAxis(amount, appLang) {
    return formatPrice({
      amount,
      currency: appLang === 'SK' ? 'EUR' : 'CZK',
    });
  }

  render() {
    const { data, title, subTitle, appLang } = this.props;

    const CustomToolTip = props => {
      const { active, payload } = props;
      if (!active || !payload) {
        return null;
      }

      return (
        <div className="custom-tooltip">
          {payload.map(item => (
            <p key={item.payload.name}>
              {item.name}:{' '}
              <strong>
                {`${formatPrice({
                  amount: item.payload.amount,
                  currency: appLang === 'SK' ? 'EUR' : 'CZK',
                })} (${item.payload.count})`}
              </strong>
            </p>
          ))}
        </div>
      );
    };

    return (
      <Wrapper>
        <div className="title">{title}</div>
        <div className="sub_title">{subTitle}</div>
        <ResponsiveContainer height={350}>
          <AreaChart
            data={data}
            type="linear"
            margin={{
              top: 5,
              right: 35,
              left: 35,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={Theme.color.primary}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={Theme.color.primary}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            <YAxis unit={appLang === 'SK' ? `\u00a0€` : `\u00a0Kč`} />
            <Tooltip content={<CustomToolTip />} />
            <Area
              type="linear"
              dataKey="amount"
              stroke={Theme.color.primary}
              fillOpacity={1}
              fill="url(#colorUv)"
              name={__('Celkový nákup')}
            >
              <Label dataKey="value" position="center" />
            </Area>
          </AreaChart>
        </ResponsiveContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background: white;
  padding: ${rem(16)};
  border-radius: ${rem(8)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: ${rem(22)};
    font-weight: bold;
    color: black;
    text-align: center;
    margin-bottom: ${rem(8)};
  }
  .sub_title {
    font-size: ${rem(16)};
    font-weight: bold;
    color: #7c828a;
    text-align: center;
    margin-bottom: ${rem(16)};
  }

  .test {
    background-color: rebeccapurple;
  }
`;

LineChartStatsComponent.propTypes = {
  appLang: PropTypes.string,
};

LineChartStatsComponent.defaultProps = {
  appLang: 'SK',
};

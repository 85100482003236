import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import Button from 'oxyrion-ui/lib/Button';
import Loader from 'oxyrion-ui/lib/Loader';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import moment from 'moment';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import LineChart from '../../Components/LineChart';
import { getDaysOfCurrentWeek, __ } from '../../Utils';
import OrdersTable from '../../Components/OrdersTable';
import WarrantyClaimsTable from '../../Components/WarrantyClaimsTable';
import { LoaderWrapper } from '../../Components/ReusableComponents';
import MessagesTable from '../../Components/MessagesTable';
import API2 from '../../API2';
import DailyCashDashboard from '../DailyCashDashboard';
import DailyOrdersDashboard from '../DailyOrders';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.m`
 flex-direction: row;
  `};
`;

const OrderTableWrapper = styled.div`
  margin-top: ${rem(24)};
  border-radius: ${rem(8)};
  padding-bottom: ${rem(24)};
  background: white;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: ${rem(1760)};
  width: 100%;
  padding: ${rem(16)};
  padding-top: ${rem(40)};
`;
const Space = styled.div`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${rem(32)};
`;

const Title = styled.div`
  width: 100%;
  margin-top: ${rem(24)};
  color: #ba6dba;
  font-size: ${rem(24)};
  font-weight: bold;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${rem(16)};
`;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      messages: [],
      warrantyClaims: [],
      dayStats: [],
      dayStatsFinished: [],
      monthStats: {},
      monthStatsFinished: {},
      ordersLoading: false,
      warrantyClaimsLoading: false,
      onlyFinished: true,
      cashRegisters: [],
    };
  }

  async componentDidMount() {
    try {
      // nacitat fees
      // nacitat stats
      // nacitat zoznam objednavok

      this.setState({
        ordersLoading: true,
        warrantyClaimsLoading: true,
      });

      const [
        messagesResult,
        orderResult,
        warrantyClaimsResult,
        { months, months_finished, week, week_finished },
        { items: testEmails },
        cashRegisters,
      ] = await Promise.all([
        API2.getEshopMessagesAction(this.props.firm, {
          createdBy: 'customer',
          limit: 5,
        }),
        API2.getEshopOrdersAction(this.props.firm, {
          limit: 5,
          eomState: 'new',
          excludeTest: true,
        }),
        API2.getEshopWarrantyClaimsAction(this.props.firm, {
          state: 'active',
          limit: 5,
        }),
        API2.getEshopOrdersStatsAction(this.props.firm, {
          year: this.props.year,
        }),
        API2.getTestEmailsList(),
        API2.getCashRegistersListAction({
          companyId: this.props.firm,
          onlyProduction: true,
        }),
      ]);

      const days = getDaysOfCurrentWeek();
      const daysWithData = days.map(d => {
        const dateInData = week.find(w => d.isSame(moment(w.name), 'day'));
        return {
          name: d.format('dd'),
          amount: dateInData ? dateInData.amount : 0,
        };
      });
      const daysWithDataFinished = days.map(d => {
        const dateInData = week_finished.find(w =>
          d.isSame(moment(w.name), 'day'),
        );
        return {
          name: d.format('dd'),
          amount: dateInData ? dateInData.amount : 0,
        };
      });

      this.setState({
        messages: messagesResult.items,
        orders: orderResult.items,
        warrantyClaims: warrantyClaimsResult.items,
        dayStats: daysWithData,
        dayStatsFinished: daysWithDataFinished,
        monthStats: months.map(m =>
          Object.assign(m, { name: moment(m.name, 'MM').format('MMM') }),
        ),
        monthStatsFinished: months_finished.map(m =>
          Object.assign(m, { name: moment(m.name, 'MM').format('MMM') }),
        ),
        ordersLoading: false,
        warrantyClaimsLoading: false,
        TEST_EMAILS: testEmails,
        cashRegisters: cashRegisters.items,
      });
    } catch (e) {
      console.log(e);
    }
  }

  currentWeekString() {
    const currentDate = moment();

    const weekStart = currentDate.clone().startOf('isoWeek');
    const weekEnd = currentDate.clone().endOf('isoWeek');

    return `${weekStart.format('Do MMM')} - ${weekEnd.format('Do MMM')}`;
  }

  renderControlBar() {
    const { history } = this.props;

    return <ControllBar history={history} name={__('Prehľad')} />;
  }

  render() {
    const {
      orders,
      messages,
      ordersLoading,
      warrantyClaims,
      warrantyClaimsLoading,
      monthStats,
      monthStatsFinished,
      dayStats,
      dayStatsFinished,
      onlyFinished,
      TEST_EMAILS,
      cashRegisters,
    } = this.state;

    if (ordersLoading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <MainWrapper>
          <Wrapper>
            <ChartsWrapper>
              <LineChart
                data={onlyFinished ? monthStatsFinished : monthStats}
                title={__('Štatistika objednávok')}
                subTitle={this.props.year}
                appLang={this.props.appLang}
              />
              <Space />
              <LineChart
                data={onlyFinished ? dayStatsFinished : dayStats}
                title={__('Objednávky aktuálny týždeň')}
                subTitle={this.currentWeekString()}
                appLang={this.props.appLang}
              />
            </ChartsWrapper>
            <CheckboxWrapper>
              <LabeledCheckbox
                checked={onlyFinished}
                labelText={__('Iba ukončené')}
                onChange={e =>
                  this.setState({
                    onlyFinished: e.target.checked,
                  })
                }
              />
            </CheckboxWrapper>

            <Title>{__('Posledné objednávky')}</Title>

            <OrderTableWrapper>
              <OrdersTable
                firm={this.props.firm}
                loading={ordersLoading}
                data={orders}
                TEST_EMAILS={TEST_EMAILS}
              />
            </OrderTableWrapper>

            <ButtonWrapper>
              <Link
                style={{
                  textDecoration: 'none',
                }}
                to="./orders"
              >
                <Button primary>{__('Zobraziť všetky')}</Button>
              </Link>
            </ButtonWrapper>

            <Title>{__('Dnešné objednávky')}</Title>

            <DailyOrdersDashboard
              firm={this.props.firm}
              loading={ordersLoading}
            />

            <ButtonWrapper>
              <Link
                style={{
                  textDecoration: 'none',
                }}
                to="./orders"
              >
                <Button primary>{__('Zobraziť všetky')}</Button>
              </Link>
            </ButtonWrapper>

            {cashRegisters.map(cr => (
              <DailyCashDashboard
                key={cr._id}
                cashRegisterId={cr._id}
                name={cr.name}
              />
            ))}

            <Title>{__('Najnovšie správy z eshopu')}</Title>

            <OrderTableWrapper>
              <MessagesTable
                firm={this.props.firm}
                loading={ordersLoading}
                data={messages}
              />
            </OrderTableWrapper>

            <ButtonWrapper>
              <Link
                style={{
                  textDecoration: 'none',
                }}
                to="./messages"
              >
                <Button primary>{__('Zobraziť všetky')}</Button>
              </Link>
            </ButtonWrapper>

            <Title>{__('Aktuálne reklamácie a vrátenia')}</Title>

            <OrderTableWrapper>
              <WarrantyClaimsTable
                firm={this.props.firm}
                loading={warrantyClaimsLoading}
                data={warrantyClaims}
              />
            </OrderTableWrapper>

            <ButtonWrapper>
              <Link
                style={{
                  textDecoration: 'none',
                }}
                to="./warranty-claims"
              >
                <Button primary>{__('Zobraziť všetky')}</Button>
              </Link>
            </ButtonWrapper>
          </Wrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Dashboard);
